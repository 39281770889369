import { RouteConfig } from 'react-router-config';
import LayoutIndex from './commons/Components/Layout/index';
import { HomeRouter } from './modules/Home/router';
import { SplashRouter } from './modules/Splash/router';

const routes: RouteConfig[] = [
  {
    component: LayoutIndex,
    routes: [...HomeRouter, ...SplashRouter],
  },
];

export default routes;
