import React, { useEffect, useRef } from 'react';

import VideoPlayer from 'react-video-js-player';

interface IProps {
  src: any;
  indexFrame: number;
  isActive?: boolean;
  poster?: string;
  onEnd?(): void;
}

function IFrameVideo({ src, isActive, poster, indexFrame, onEnd }: IProps) {
  const refPlayer = useRef();

  useEffect(() => {
    if (refPlayer.current) {
      if (isActive) {
        (refPlayer.current as any).src(src);
        // (refPlayer.current as any).reset();
        (refPlayer.current as any).currentTime(0);
        (refPlayer.current as any).play();
      } else {
        (refPlayer.current as any).currentTime(0);
        (refPlayer.current as any).pause();
      }
    }
    // eslint-disable-next-line
  }, [isActive]);

  const onPlayerReady = (player: any) => {
    player.muted(true);
    if (isActive) {
      player.autoplay(true);
    }
    refPlayer.current = player;
  };

  // console.log('video component render');

  return (
    <div key={indexFrame} className="w-100 h-100">
      <VideoPlayer
        key={indexFrame}
        controls={false}
        src={src}
        width="100%"
        height="100%"
        onEnd={onEnd}
        onReady={onPlayerReady}
        poster={poster}
      />
    </div>
  );
}

function areEqual(prevProps: IProps, nextProps: IProps) {
  return (
    prevProps.src === nextProps.src &&
    prevProps.isActive === nextProps.isActive &&
    prevProps.indexFrame === nextProps.indexFrame
  );
}

export default React.memo(IFrameVideo, areEqual);
