import env from '@/env';
import { useMutation } from 'react-query';
import { CacheRoute } from './useOfflinePlaylist';
import useSendScreenshot from './useSendScreenshot';

export default function useCaptureScreenshot(hashKey?: string) {
  const { SendScreenshot } = useSendScreenshot();

  return useMutation(async () => {
    const getRoute = `${env.piApiEndPoint}/${CacheRoute.CAPTURE_SCREENSHOT}`;
    const response = await fetch(getRoute, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const rp = await response.json();
    if (rp?.data) {
      SendScreenshot(rp.data);
    }
  });
}
