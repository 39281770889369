import { getSDK } from '@/graphql/client';
import { useQuery } from 'react-query';

const SETTINGS = 'SETTINGS';

export default function useSettings() {
  return useQuery(
    SETTINGS,
    async () => {
      return getSDK().Settings();
    },
    {
      enabled: true,
      onSuccess: (res) => {},
      onError: (error: any) => {},
    },
  );
}
