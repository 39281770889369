import { DevicePlaylistSchedule, Frame, FrameType, ScheduleLayoutSettings } from '@/graphql/generated/graphql';
import React, { useEffect, useState } from 'react';
import { SizeInterface } from './FrameComponent';
import PlaylistSlider from './PlaylistSlider';

interface IProps {
  currentSchedule?: DevicePlaylistSchedule | null;
  size: SizeInterface;
  layoutSettings: ScheduleLayoutSettings;
}

function PlaylistContent(props: IProps) {
  const convertFrameDocument = (frames?: Frame[] | null) => {
    const framesNew = (frames || ([] as Frame[])).reduce((ac: Frame[], cu) => {
      if (cu.type === FrameType.Document) {
        const { documentImages, ...otherInfo } = cu;
        return [
          ...ac,
          ...((documentImages || []).map((i) => ({
            ...otherInfo,
            ...i,
          })) as Frame[]),
        ];
      }
      return [...ac, cu];
    }, []);
    return framesNew;
  };

  const [playlist, setPlaylist] = useState<any | null>(null);

  useEffect(() => {
    if (props.currentSchedule) {
      let frames = convertFrameDocument(props.currentSchedule?.playlist?.frames);
      setPlaylist({
        ...props.currentSchedule?.playlist,
        frames,
      });
    }
  }, [props.currentSchedule, props.currentSchedule?.playlist?.version]);

  return playlist?.frames ? (
    <PlaylistSlider frames={playlist?.frames} playlistId={playlist._id} version={playlist.version} size={props.size} />
  ) : null;
}

export default React.memo(PlaylistContent, (prevProps, nextProps) => {
  return (
    prevProps.currentSchedule?.playlistId?.toString() === nextProps.currentSchedule?.playlistId?.toString() &&
    prevProps.currentSchedule?.playlist?.version === nextProps.currentSchedule?.playlist?.version &&
    prevProps.size.ratio === nextProps.size.ratio
  );
});
