import env from '@/env';
import { useCallback } from 'react';

const useRestartDevice = () => {
  const onRestartDevice = useCallback(() => {
    fetch(`${env.piApiEndPoint}/restart-device/`, {
      method: 'POST',
    }).catch((error) => {
      console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onRestartDevice,
  };
};

export default useRestartDevice;
