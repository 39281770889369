/* eslint-disable react-hooks/exhaustive-deps */
/** @format */

import { deepObjectEqual } from '@helpers/object';
import React from 'react';
import './style.scss';

interface IProps {
  src: any;
  indexFrame: Number;
}

function IFrameWebUrl({ src, indexFrame }: IProps) {
  return (
    <iframe
      id={`FRAME_WEB_URL_${indexFrame}`}
      className="holds-the-iframe position-absolute w-100 h-100"
      key={Math.random()}
      style={{ top: 0 }}
      src={src}
      title={`FRAME_WEB_URL_${indexFrame}`}
      scrolling="no"
    ></iframe>
  );
}

export default React.memo(IFrameWebUrl, (a, b) => {
  if (deepObjectEqual(a, b)) {
    return true;
  }
  return false;
});
