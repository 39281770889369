import { getSDK } from '@/graphql/client';
import { globalState } from '@modules/state';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import useGetDeviceInfo from '@hooks/useGetDeviceInfo';

const GET_DEVICE_VERSION = 'GET_DEVICE_VERSION';

export default function useGetDeviceVersion(token?: string | null) {
  const [appState, setAppState] = useRecoilState(globalState);
  const { refetch: getDeviceInfo } = useGetDeviceInfo();

  return useQuery(
    GET_DEVICE_VERSION,
    () => {
      return getSDK().getDeviceVersion();
    },
    {
      enabled: !!token,
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      onSuccess: (res) => {
        if (res && res.getDeviceVersion !== undefined) {
          const deviceVersion = res.getDeviceVersion;
          // Lần đầu get deviceVersion, call api lấy thông tin device
          if (appState.deviceVersion === 0) {
            getDeviceInfo();
          } else {
            if (deviceVersion === -1) {
              /**
               * deviceVersion = -1 là do không tìm thấy device trong hệ thống
               * Tiến hành xóa state
               */
              setAppState((state) => ({ ...state, deviceVersion, currentDeviceObj: null }));
            } else {
              /**
               * Những lần sau, nếu có deviceVersion mới, tiến hành reload app
               */
              if (appState.deviceVersion !== deviceVersion) {
                window.location.reload();
              }
            }
          }
        }
      },
    },
  );
}
