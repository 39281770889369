import env from '@/env';
import { clearSchedulesInYear } from '@modules/Home/helpers/check-schedule';
import { useCallback } from 'react';

const useClearDeviceStorage = () => {
  const onClearDeviceStorage = useCallback(async () => {
    await fetch(`${env.piApiEndPoint}/device/clear-storage/`, {
      method: 'POST',
    }).catch((error) => {
      console.log(error);
    });
    await clearSchedulesInYear();
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onClearDeviceStorage,
  };
};

export default useClearDeviceStorage;
