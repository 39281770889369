import Dexie, { Table } from 'dexie';

export interface Schedules {
  id?: number;
  day?: number;
  year: number;
  scheduleVersion: number;
  schedules: any;
  schedulesSecond: any;
}

export class MySubClassedDexie extends Dexie {
  schedules!: Table<Schedules>;

  constructor() {
    super('myDatabase');
    this.version(1).stores({
      schedules: '++id, name, age', // Primary key and indexed props
    });
  }
}

export const db = new MySubClassedDexie();
