import {
  DevicePlaylistSchedule,
  FrameDirection,
  PlaylistScreenType,
  ScheduleLayoutSettings,
} from '@/graphql/generated/graphql';
import { globalState } from '@modules/state';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SizeInterface } from './FrameComponent';
import NoPlaylist from './NoPlaylist';
import PlaylistContent from './PlaylistContent';

interface IProps {
  size: SizeInterface;
  layoutSettings: ScheduleLayoutSettings;
}

export default function PlaylistLayout(props: IProps) {
  const [playlists, setPlaylists] = useState<DevicePlaylistSchedule[]>([]);
  const appState = useRecoilValue(globalState);

  useEffect(() => {
    if (appState.currentSchedules) {
      setPlaylists(appState.currentSchedules);
    }
  }, [appState.currentSchedules]);

  const getWidthHeight = (): { width: string; height: string } => {
    switch (props.layoutSettings.screenType) {
      case PlaylistScreenType.FulScreen:
        return {
          width: '100%',
          height: '100%',
        };
      case PlaylistScreenType.SplitScreen:
        if (props.layoutSettings.orientation === FrameDirection.Horizontal) {
          return {
            width: '50%',
            height: '100%',
          };
        } else {
          return {
            width: '100%',
            height: '50%',
          };
        }
    }
    return {
      width: '100%',
      height: '100%',
    };
  };

  return playlists.length ? (
    <div className="playlist_container w-100 h-100">
      <>
        {props.layoutSettings.screenType === PlaylistScreenType.FulScreen ? (
          <div
            style={{
              width: getWidthHeight().width,
              height: getWidthHeight().height,
              backgroundColor: props.layoutSettings.backgroundColor || '#fff',
            }}
          >
            <PlaylistContent layoutSettings={props.layoutSettings} size={props.size} currentSchedule={playlists[0]} />
          </div>
        ) : props.layoutSettings.screenType === PlaylistScreenType.SplitScreen ? (
          <>
            <div
              className={`${
                props.layoutSettings.orientation === FrameDirection.Horizontal
                  ? 'playlist_split_content_horizontal'
                  : 'playlist_split_content_vertical'
              } position-relative h-100`}
            >
              <div
                className="frame_split"
                style={{
                  width: getWidthHeight().width,
                  height: getWidthHeight().height,
                  backgroundColor: props.layoutSettings.backgroundColor || '#fff',
                }}
              >
                {playlists[0] ? (
                  <PlaylistContent
                    layoutSettings={props.layoutSettings}
                    size={props.size}
                    currentSchedule={playlists[0]}
                  />
                ) : (
                  <NoPlaylist />
                )}
              </div>

              <div
                className="frame_split"
                style={{
                  width: getWidthHeight().width,
                  height: getWidthHeight().height,
                  backgroundColor: props.layoutSettings.backgroundColorSecond || '#fff',
                }}
              >
                {playlists[1] ? (
                  <PlaylistContent
                    layoutSettings={props.layoutSettings}
                    size={props.size}
                    currentSchedule={playlists[1]}
                  />
                ) : (
                  <NoPlaylist />
                )}
              </div>
            </div>
          </>
        ) : null}
      </>
    </div>
  ) : null;
}
