import { FrameDirection, PlaylistScreenType, Resolutions } from '../graphql/generated/graphql';

export interface ResolutionObj {
  width: number;
  height: number;
}

export function calculateSize(
  resolution: Resolutions,
  screenType: PlaylistScreenType,
  direction: FrameDirection,
  clientW: number,
  clientH: number,
): { ratio: number; width: number; height: number } {
  const { width: resolutionW, height: resolutionH } = getSizeSlideByResolutionAndFramesDirec(resolution, direction);
  let { w, h } = getResolutionSizeByScreenType({ width: resolutionW, height: resolutionH }, screenType, direction);
  let clientWidthByScreenType, clientHeightByScreenType;
  // console.log('currentPlaylist222222', resolution, screenType, FrameDirection);
  if (screenType === PlaylistScreenType.SplitScreen) {
    if (direction === FrameDirection.Horizontal) {
      clientWidthByScreenType = clientW / 2;
      clientHeightByScreenType = clientH;
    } else {
      clientWidthByScreenType = clientW;
      clientHeightByScreenType = clientH / 2;
    }
  } else {
    clientWidthByScreenType = clientW;
    clientHeightByScreenType = clientH;
  }

  let wRatio, hRatio, ratio;
  wRatio = clientWidthByScreenType / w;
  hRatio = clientHeightByScreenType / h;

  ratio = Math.min(wRatio, hRatio);

  return {
    width: w * ratio,
    height: h * ratio,
    ratio: ratio,
  };
}

export const getResolutionSizeByScreenType = (
  resolution: ResolutionObj,
  screenType: PlaylistScreenType,
  frameDirection: FrameDirection,
): {
  w: number;
  h: number;
} => {
  let w = resolution.width;
  let h = resolution.height;
  if (screenType === PlaylistScreenType.SplitScreen) {
    if (frameDirection === FrameDirection.Horizontal) {
      return { w: w / 2, h };
    } else {
      return { w: w, h: h / 2 };
    }
  } else {
    if (frameDirection === FrameDirection.Horizontal) {
      return { w, h };
    } else {
      return { w: w, h: h };
    }
  }
};

export const getSizeSlideByResolutionAndFramesDirec = (res: any, frameDirection: FrameDirection) => {
  switch (res) {
    case Resolutions.Hd:
    case '1280x720':
      if (frameDirection === FrameDirection.Horizontal) {
        return { width: 1280, height: 720 };
      } else {
        return { width: 720, height: 1280 };
      }
    case Resolutions.FullHd:
    case '1920x1080':
      if (frameDirection === FrameDirection.Horizontal) {
        return { width: 1920, height: 1080 };
      } else {
        return { width: 1080, height: 1920 };
      }

    default:
      return { width: 0, height: 0 };
  }
};
