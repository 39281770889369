import env from '@/env';
import { DirectionRotateType } from '@/graphql/generated/graphql';
import { useMutation } from 'react-query';
import { CacheRoute } from './useOfflinePlaylist';

export default function useRotateScreen() {
  return useMutation(async (direction: DirectionRotateType) => {
    let postRoute = `${env.piApiEndPoint}/${CacheRoute.ROTATE_SCREEN}`;
    const rawResponse = await fetch(postRoute, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ direction }),
    });
    const content = await rawResponse.json();
    return content;
  });
}
