import { appSubscription } from '@/graphql/client';
import {
  AdminDeviceScreenStatus,
  Device,
  DevicePubSubType,
  GroupStatus,
  Setting,
  SettingType,
} from '@/graphql/generated/graphql';
import { setWsLink } from '@helpers/token';
import useCaptureScreenshot from '@hooks/useCaptureScreenshot';
import useChangeCecPower, { CecStatus } from '@hooks/useChangeCecPower';
import useClearDeviceStorage from '@hooks/useClearDeviceStorage';
import useConnectWifiByPi from '@hooks/useConnectWifiByPi';
import { DEVICE_SUBS } from '@hooks/useDeviceSubs';
import useGetCecStatus from '@hooks/useGetCecStatus';
import useGetDeviceVersion from '@hooks/useGetDeviceVersion';
import useGetHashKey from '@hooks/useGetHashKey';
import useGetListWifi from '@hooks/useGetListWifi';
import useRestartDevice from '@hooks/useRestartDevice';
import useRotateScreen from '@hooks/useRotateScreen';
import useSettings from '@hooks/useSettings';
import useCreateDevice from '@modules/SettingsPage/hooks/useCreateDevice';
import { nanoid } from 'nanoid/non-secure';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { useRecoilState } from 'recoil';
import { setHistory } from '../../../helpers/history';
import { globalState } from '../../../modules/state';
import LocalClock from '../Clock/Clock';
import Loading from '../Loading';
import MarqueeText, { GroupMessage } from '../MarqueeText';
import Sidebar from '../Sidebar/index';
import StatusNetWork from '../StatusNetWork/index';
import './style.scss';

let listener: any;

const LayoutIndex = (props: any) => {
  const history = useHistory();
  const [appState, setAppState] = useRecoilState(globalState);
  const refTimeScreenshot = useRef<any>();
  const [groupMessage, setGroupMessage] = useState<GroupMessage | null>(null);

  useEffect(() => {
    if (appState.token) {
      setWsLink(appState.token);
    }
  }, [appState.token]);

  const { onRestartDevice } = useRestartDevice();
  const { onClearDeviceStorage } = useClearDeviceStorage();
  const { mutate: changeCecPower } = useChangeCecPower();
  const { mutate: captureScreenshot } = useCaptureScreenshot();

  // Lấy dữ liệu cần thiết
  const { data: generalSettings } = useSettings();

  // Lấy hash key
  useGetHashKey(appState.hashKey);
  // Sử dụng hashKey để tạo device
  useCreateDevice(appState.deviceCode, appState.deviceCodeHash);
  // Sử dụng device token để lấy thông tin device
  useGetDeviceVersion(appState.token);
  // Định kỳ lấy screen status
  useGetCecStatus(appState.token);

  // Lấy danh sách & connect wifi
  const { mutate: getListWifi } = useGetListWifi();
  const { mutate: onConnectWifiByPi } = useConnectWifiByPi();

  // Xoay màn hình
  const { mutate: onRotateScreen } = useRotateScreen();

  //1. Xử lý các sự kiện trong lần đầu tiên mở app
  useEffect(() => {
    setHistory(history);
    // eslint-disable-next-line
  }, []);

  const takeScreenshotBody = () => {
    captureScreenshot();
  };

  // Tự động chụp ảnh màn hình gửi lên theo định kỳ
  useEffect(() => {
    if (generalSettings?.settings?.length) {
      generalSettings?.settings.map((setting: Setting) => {
        if (setting.key === SettingType.Device && setting.value[0].key === 'timeTakeSrc') {
          if (!refTimeScreenshot.current) {
            refTimeScreenshot.current = setInterval(() => {
              takeScreenshotBody();
            }, Number(setting.value[0].value) * 60000);
          }
        }
        return undefined;
      });
    } else {
      if (refTimeScreenshot.current) {
        clearInterval(refTimeScreenshot.current);
      }
    }
    // eslint-disable-next-line
  }, [generalSettings?.settings]);

  //======================Check cec device on/off========================//
  useEffect(() => {
    // Khi đã có group schedules và tính toán group xong
    if (appState.isSchedulesCalculated) {
      if (appState.currentDeviceObj?.adminScreenStatus) {
        // Admin không chủ động bật/tắt, thì màn hình tự động bật/tắt theo lịch trình chạy playlist
        if (
          appState.currentDeviceObj?.adminScreenStatus === AdminDeviceScreenStatus.Auto &&
          appState.isSchedulesCalculated
        ) {
          if (appState.currentSchedules?.length) {
            changeCecPower(CecStatus.on);
          } else {
            changeCecPower(CecStatus.standby);
          }
        } else {
          // Màn hình bật/tắt theo setting của admin
          changeCecPower(
            appState.currentDeviceObj?.adminScreenStatus === AdminDeviceScreenStatus.On
              ? CecStatus.on
              : CecStatus.standby,
          );
        }
      } else {
        if (appState.currentSchedules?.length) {
          changeCecPower(CecStatus.on);
        } else {
          changeCecPower(CecStatus.standby);
        }
      }
    } else {
      if (
        appState.currentDeviceObj?.adminScreenStatus &&
        appState.currentDeviceObj?.adminScreenStatus !== AdminDeviceScreenStatus.Auto
      ) {
        changeCecPower(
          appState.currentDeviceObj?.adminScreenStatus === AdminDeviceScreenStatus.On
            ? CecStatus.on
            : CecStatus.standby,
        );
      } else {
        // Khi không có group hoặc group chưa lên lịch
        if (appState.currentDeviceObj?.group && appState.currentDeviceObj.group.status !== GroupStatus.Approved) {
          changeCecPower(CecStatus.standby);
        }
      }
    }
    // eslint-disable-next-line
  }, [appState.currentDeviceObj?.adminScreenStatus, appState.currentSchedules?.length, appState.isSchedulesCalculated]);

  useEffect(() => {
    if (appState.token && appState.currentDeviceObj?._id) {
      const subscription = appSubscription(DEVICE_SUBS);
      listener = subscription.subscribe(async (data: any) => {
        // Device action
        if (
          data?.data?.deviceSubs?.data?._id === appState.currentDeviceObj?._id ||
          data?.data?.deviceSubs?.data?.device?._id === appState.currentDeviceObj?._id ||
          data?.data?.deviceSubs?.data?.device?.groupId === appState.currentDeviceObj?.groupId
        ) {
          switch (data.data.deviceSubs.type) {
            /**
             * Scan danh sách wifi
             */
            case DevicePubSubType.ScanWifiDevice:
              getListWifi();
              break;

            /**
             * connect wifi và gửi thông tin status server để hiển thị lên cms
             */
            case DevicePubSubType.ConnectWifi:
              const ssid = data.data.deviceSubs.data?.ssid;
              const password = data.data.deviceSubs.data?.password ? data.data.deviceSubs.data?.password : undefined;
              onConnectWifiByPi({ ssid: ssid, password: password });
              break;

            /**
             * Thực hiện chụp ảnh màn hình
             */
            case DevicePubSubType.CaptureScreen:
              if (document.body) {
                takeScreenshotBody();
              }
              break;

            /**
             * Refresh lại trình duyệt
             */
            case DevicePubSubType.ReloadClientApp:
              window.location.reload();
              break;

            /**
             * Restart hệ điều hành
             */
            case DevicePubSubType.RestartDevice:
              onRestartDevice();
              break;

            /**
             * Xóa thư mục storage để tải lại danh sách phát khi có sự cố về download
             */
            case DevicePubSubType.ClearDeviceStorage:
              onClearDeviceStorage();
              break;

            /**
             * Kiểm tra màn hình hiện tại có cần phải xoay không
             * Tiến hành update state để xoay lại màn hình
             * Luồng xoay màn hình chỉ thực hiện khi state thay đổi
             */
            case DevicePubSubType.RotateScreen:
              onRotateScreen(data?.data?.deviceSubs?.data?.direction);
              break;

            case DevicePubSubType.AdminForceUpdateScreenStatus:
              if (data?.data?.deviceSubs?.data?.adminScreenStatus) {
                setAppState((state) => ({
                  ...state,
                  currentDeviceObj: {
                    ...(state.currentDeviceObj as Device),
                    adminScreenStatus: data?.data?.deviceSubs?.data?.adminScreenStatus,
                  },
                }));
              }

              break;

            default:
              break;
          }
        }
        // Group action
        if (data?.data?.deviceSubs?.data?.groupId === appState.currentDeviceObj?.groupId) {
          switch (data.data.deviceSubs.type) {
            case DevicePubSubType.ReloadClientApp:
              window.location.reload();
              break;

            case DevicePubSubType.SendGroupMessage:
              setGroupMessage({ ...data?.data?.deviceSubs?.data?.data, uid: nanoid() });
              break;

            default:
              break;
          }
        }
      });
    }
    return () => {
      listener && listener.unsubscribe();
    };
    // eslint-disable-next-line
  }, [appState.token, appState.currentDeviceObj?._id, appState.currentDeviceObj?.groupId]);

  return (
    <div className="layout h-100">
      <Sidebar />
      <StatusNetWork />
      <div className="h-100">
        {appState.deviceCode ? (
          <>
            {props.children}
            {
              <div className="group-message-container">
                {appState.currentDeviceObj?.group?.timezone && (
                  <LocalClock timezone={appState.currentDeviceObj?.group?.timezone} />
                )}
                <MarqueeText data={groupMessage} />
              </div>
            }
            {!!props.route && renderRoutes(props.route.routes)}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default LayoutIndex;
