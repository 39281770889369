import { getSDK } from '@/graphql/client';
import { Device, GroupStatus, ScheduleLayoutSettings } from '@/graphql/generated/graphql';
import { calculateSchedulesData, clearSchedulesInYear } from '@modules/Home/helpers/check-schedule';
import { globalState } from '@modules/state';
import moment_tz from 'moment-timezone';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import useRotateScreen from './useRotateScreen';

const GET_DEVICE_INFO = 'GET_DEVICE_INFO';

/**
 * Api chỉ được call khi useGetDeviceVersion nhận giá trị mới
 * Sau khi có dữ liệu lịch của group, tiến hành tính toán lịch trình
 * Nếu không có thông tin lịch chạy của group, thì xóa dữ liệu cũ đi
 * @param version
 * @param token
 * @returns
 */
export default function useGetDeviceInfo() {
  const [appState, setAppState] = useRecoilState(globalState);
  const { mutate: onRotateScreen } = useRotateScreen();

  return useQuery(
    [GET_DEVICE_INFO, appState.deviceVersion],
    () => {
      return getSDK().getDeviceInfo();
    },
    {
      enabled: false,
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      onSuccess: async (res) => {
        if (res && res.getDeviceInfo) {
          const deviceData = res.getDeviceInfo;
          if (res.getDeviceInfo.group?.timezone) {
            moment_tz.tz.setDefault(res.getDeviceInfo.group?.timezone);
          }
          let schedulesInDay: any;
          // Tính toán lại lịch trình của device
          if (
            deviceData.group &&
            deviceData.group.status === GroupStatus.Approved &&
            deviceData.group.scheduleVersion &&
            deviceData.group.layoutSettings?.screenType
          ) {
            schedulesInDay = await calculateSchedulesData(
              deviceData.group.layoutSettings?.screenType,
              deviceData.group.schedules,
              deviceData.group.schedulesSecond,
              deviceData.group.scheduleVersion,
              deviceData.momentDateFormat || 'DD.MM.YYYY',
            );
          } else {
            // Xóa lịch chạy khi không có thông tin group trả về (group bị xóa, bị xóa khỏi group, bị xóa khỏi company...)
            clearSchedulesInYear();
          }
          setAppState((state) => ({
            ...state,
            deviceVersion: res.getDeviceInfo?.version || 0,
            currentDeviceObj: res.getDeviceInfo as Device,
            layoutSettings: deviceData.group?.layoutSettings as ScheduleLayoutSettings,
            schedulesInDay,
          }));
          // Xoay màn hình theo device direction
          if (deviceData.direction) onRotateScreen(deviceData.direction);
        }
      },
    },
  );
}
