import env from '@/env';
import { useQuery } from 'react-query';
import { CacheRoute } from './useOfflinePlaylist';

const CHROMIUM_PING_PONG = 'CHROMIUM_PING_PONG';

export default function useChromiumPingPong() {
  return useQuery(
    [CHROMIUM_PING_PONG],
    async () => {
      const getRoute = `${env.piApiEndPoint}/${CacheRoute.CHROMIUM_PING_PONG}`;
      await fetch(getRoute, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    {
      enabled: true,
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    },
  );
}
