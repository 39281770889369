import { SendWifiInput } from '@/graphql/generated/graphql';
import { getSDK } from '../graphql/client';

export default function useSendWifiDevice() {
  const SendWifi = async (input: SendWifiInput) => {
    try {
      await getSDK().SendWifiDevice({ input });
    } catch (error) {
      console.log('error: ', error);
    }
  };
  return { SendWifi };
}
