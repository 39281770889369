import { Device, Frame, FrameType } from '@/graphql/generated/graphql';
import { getLinkFromCache } from '@helpers/url';
import { useCallback } from 'react';

export enum CacheRoute {
  GET_PLAYLIST = 'get-playlist',
  UPDATE_CACHE = 'update-cache',
  CEC_STATUS = 'cec-status',
  ROTATE_SCREEN = 'rotate-screen',
  CHROMIUM_PING_PONG = 'chrome/ping',
  GET_HASH_KEY = 'chrome/get-hash-key',
  CAPTURE_SCREENSHOT = 'chrome/capture-screenshot',
}

const getLinks = (frames: Frame[]) => {
  return frames.reduce((ac: any[], cu: Frame) => {
    if (cu.type === FrameType.Document) {
      if (cu.documentImages) {
        return [...ac, ...cu.documentImages.map((a) => a.url)];
      }
    } else if (cu.type === FrameType.Video) {
      let array: any = [];
      if (cu.videoProfiles) {
        array = [...array, ...cu.videoProfiles.map((a) => a.url)];
      }
      return [...ac, ...array];
    } else if (cu.type === FrameType.Image) {
      if (cu.image && cu?.image?.url) {
        return [...ac, cu?.image?.url];
      }
    }
    return ac;
  }, []);
};

export interface PlaylistUrls {
  updatedAt?: number | null;
  playlistId: string;
  urls: any[];
}

export interface CachePlaylistUrls {
  otherPlaylist: PlaylistUrls[];
  currentPlaylist: any;
}

const useOfflinePlaylist = () => {
  const onDetectUrlFromPlaylist = useCallback((device: Device): CachePlaylistUrls => {
    const allPlaylist: PlaylistUrls[] = [];
    const schedules = device.group?.schedules;
    const schedulesSecond = device.group?.schedulesSecond;
    if (schedules?.length) {
      for (let i = 0; i < schedules.length; i++) {
        if (schedules[i].playlist) {
          const urls = getLinks(schedules[i].playlist?.frames || []);
          const urls_second: any[] = [];
          allPlaylist.push({
            updatedAt: schedules[i].playlist?.updatedAt || 0,
            playlistId: schedules[i].playlistId,
            urls: [...urls, ...urls_second],
          });
        }
      }
    }
    if (schedulesSecond?.length) {
      for (let i = 0; i < schedulesSecond.length; i++) {
        if (schedulesSecond[i].playlist) {
          const urls = getLinks(schedulesSecond[i].playlist?.frames || []);
          const urls_second: any[] = [];
          allPlaylist.push({
            updatedAt: schedulesSecond[i].playlist?.updatedAt || 0,
            playlistId: schedulesSecond[i].playlistId,
            urls: [...urls, ...urls_second],
          });
        }
      }
    }
    const data = {
      otherPlaylist: allPlaylist,
      currentPlaylist: null,
    };

    return data;
    // eslint-disable-next-line
  }, []);

  const onGetLinkFromCache = useCallback((playlistId: string, uri: string) => {
    return getLinkFromCache(playlistId, uri);
  }, []);

  return {
    onDetectUrlFromPlaylist,
    onGetLinkFromCache,
  };
};

export default useOfflinePlaylist;
