import env from '@/env';
import { useMutation } from 'react-query';
import useStatusConnectWifi from '@hooks/useStatusConnectWifi';

export default function useConnectWifiByPi() {
  const { sendWifiConnectionStatus } = useStatusConnectWifi();
  return useMutation(async (input: any) => {
    let postRoute = `${env.piApiEndPoint}/connect-wifi`;
    const rawResponse = await fetch(postRoute, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    });
    const statusConnectWifi = await rawResponse.json();
    if (statusConnectWifi) {
      sendWifiConnectionStatus({
        ssid: statusConnectWifi.ssid,
        password: statusConnectWifi.password,
        status: statusConnectWifi.status,
      });
    }
    return statusConnectWifi;
  });
}
