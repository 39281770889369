import { useTranslation } from 'react-i18next';
import ImgNotPlaylist from '../../../assets/images/img_not_playlist.png';

export default function NoPlaylist() {
  const { t } = useTranslation(['translation']);

  return (
    <div className="no_connect h-100 w-100">
      <div className={`bg-opacity-7 w-100 h-100 d-flex justify-content-center align-items-center flex-wrap flex-row`}>
        <div className={`wrapper wrapper-vertical`}>
          <div className={`container_content bg-opacity-3 p-4`}>
            <div className="d-flex flex-column align-items-center d-flex gap-3">
              <img className={`img_not_playlist`} src={ImgNotPlaylist} alt="img" />
              <span className={`title text-capitalize`}>{t('current_not_playlist')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
