import { GroupMessageDirection, GroupMessageSpeed } from '@/graphql/generated/graphql';
import React, { useEffect, useState } from 'react';
import Marquee from './Marquee';

export interface GroupMessage {
  direction: GroupMessageDirection;
  message: string;
  speed: GroupMessageSpeed;
  loop: number;
  uid: string;
}

interface IProps {
  data: GroupMessage | null;
}

function MarqueeText(props: IProps) {
  const [groupMessage, setGroupMessage] = useState<GroupMessage | null>(null);
  const messageSpeed = (speed: GroupMessageSpeed) => {
    switch (speed) {
      case GroupMessageSpeed.Slow:
        return 60;
      case GroupMessageSpeed.Medium:
        return 100;
      case GroupMessageSpeed.Fast:
        return 160;
    }
  };

  useEffect(() => {
    if (props.data?.uid) {
      setGroupMessage((pre) => {
        return pre && props.data
          ? {
              ...pre,
              ...props.data,
              loop:
                props.data.loop === 0
                  ? 0
                  : props.data.message.trim().length === 0 || props.data.loop < 0
                  ? -1
                  : pre.loop >= 0 && props.data.loop > pre.loop
                  ? props.data.loop * 2 - 1 + pre.loop
                  : props.data.loop < pre.loop
                  ? props.data.loop * 2
                  : props.data.loop,
            }
          : props.data;
      });
    }
    // eslint-disable-next-line
  }, [props.data?.uid]);

  const onFinish = () => {
    setGroupMessage(null);
  };
  if (!groupMessage || (groupMessage && groupMessage.loop < 0) || groupMessage?.message.trim().length === 0) {
    return null;
  }
  return (
    <div className="marquee-text-container">
      <Marquee
        speed={messageSpeed(groupMessage.speed)}
        loop={groupMessage.loop <= 0 ? 0 : groupMessage.loop}
        onFinish={onFinish}
        className="marquee-text"
        gradientWidth={0}
        direction={groupMessage.direction === GroupMessageDirection.LeftToRight ? 'right' : 'left'}
      >
        {groupMessage.message}
      </Marquee>
    </div>
  );
}

function areEqual(prevProps: IProps, nextProps: IProps) {
  return prevProps.data?.uid === nextProps.data?.uid;
}

export default React.memo(MarqueeText, areEqual);
