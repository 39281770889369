import { ApolloClient, ApolloLink, DocumentNode, InMemoryCache, OperationVariables } from '@apollo/client';
import { GraphQLClient } from 'graphql-request';
import env from '../env';
import { getToken } from '../helpers/device_code';
import { getLocalLanguage, getWsLink } from './../helpers/token';
import { getSdk } from './generated/graphql';

export const getClient = (auth = true, signal?: AbortSignal) => {
  const headers: { [k: string]: any } = {};

  const lang = getLocalLanguage();

  if (auth) {
    headers.authorization = auth ? `Bearer ${getToken()}` : undefined;
  }
  headers['x-lang'] = lang;

  const graphQLClient = new GraphQLClient(env.apiEndPoint, {
    headers,
    signal,
  });

  return graphQLClient;
};

export const getSDK = (auth = true, signal?: AbortSignal) => {
  const client = getClient(auth, signal);
  return getSdk(client);
};

let clientSub: any;

export const appSubscription = (query: DocumentNode, variables?: OperationVariables) => {
  if (!clientSub) {
    clientSub = new ApolloClient({
      link: ApolloLink.from([getWsLink()]),
      cache: new InMemoryCache(),
    });
  }
  return clientSub.subscribe({
    query,
    variables,
  });
};

// eslint-disable-next-line
export default {
  getClient,
  getSDK,
};
