import React from 'react';
import { CoverPage, CoverModal, Row } from './styled';
import { Spin } from 'antd';
import { CSSProperties } from 'styled-components';
import { SpinSize } from 'antd/lib/spin';

interface TransferProps {
  type?: 'inline' | 'fullpage' | 'modal' | 'fixed';
  style?: CSSProperties;
  sizeSpin?: SpinSize;
  className?: string;
}

const Loading = ({ type = 'fullpage', style = {}, sizeSpin, className }: TransferProps) => {
  if (type === 'inline') {
    return (
      <Row className={`${className}`} style={style}>
        <Spin size={sizeSpin} />
      </Row>
    );
  }

  if (type === 'modal') {
    return (
      <CoverModal className={`${className}`} style={{ ...style, position: 'absolute' }}>
        <Spin />
      </CoverModal>
    );
  }

  return (
    <CoverPage className={`${className}`} style={{ ...style, position: 'fixed' }}>
      <Spin />
    </CoverPage>
  );
};

export default Loading;
