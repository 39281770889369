import env from '@/env';
import { db } from '@modules/Home/helpers/db';
import {
  DevicePlaylistSchedule,
  DisplayMode,
  PlaylistScreenType,
  ScheduleType,
  SpecialDayScheduleData,
} from '@/graphql/generated/graphql';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import moment_tz from 'moment-timezone';
const timeFormat = 'HH:mm';

interface PlaylistSchedule extends DevicePlaylistSchedule {
  start?: number;
  end?: number;
  priority?: number;
  priorityTimeRange?: number;
}

const getTimeInDayBySeconds = (time: string) => {
  const t = moment(time, timeFormat);
  return t.get('hour') * 60 * 60 + t.get('minute') * 60;
};

const setScheduleInDay = (
  schedules: DevicePlaylistSchedule[],
  formatDate: string,
  dayInYear: number,
  currentDate: Moment,
) => {
  const currentYear = currentDate.get('year');
  //chia schedule theo type
  let SchedulesSpecialDay: PlaylistSchedule[] = []; //ưu tiên 1
  let SchedulesDaily: PlaylistSchedule[] = []; //ưu tiên 2
  let SchedulesWeekly: PlaylistSchedule[] = []; //ưu tiên 3
  let SchedulesMonthly: PlaylistSchedule[] = []; //ưu tiên 4

  let schedulesInDay: any[] = [];
  if (schedules?.length) {
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].type === ScheduleType.SpecialDay) {
        SchedulesSpecialDay.push(schedules[i]);
      } else if (schedules[i].type === ScheduleType.Daily) {
        SchedulesDaily.push(schedules[i]);
      } else if (schedules[i].type === ScheduleType.Weekly) {
        SchedulesWeekly.push(schedules[i]);
      } else if (schedules[i].type === ScheduleType.Monthly) {
        SchedulesMonthly.push(schedules[i]);
      }
    }

    const date = moment().dayOfYear(dayInYear).startOf('day');

    // Kiểm tra special day
    for (let j = 0; j <= SchedulesSpecialDay.length; j++) {
      const s = { ...SchedulesSpecialDay[j] };
      if (s?.specialDayData?.length) {
        // eslint-disable-next-line
        s?.specialDayData.map((specialDay: SpecialDayScheduleData) => {
          if (
            !isEmpty(specialDay) &&
            specialDay.date &&
            ((moment(specialDay.date, formatDate).dayOfYear() === dayInYear &&
              moment(specialDay.date, formatDate).year() === currentYear) ||
              (specialDay.toDate &&
                moment(specialDay.toDate, formatDate).dayOfYear() >= dayInYear &&
                moment(specialDay.toDate, formatDate).year() >= currentYear))
          ) {
            const newS: any = { ...s };
            if (specialDay.timeInDay?.startOfDay && specialDay.timeInDay?.endOfDay) {
              newS.start = getTimeInDayBySeconds(specialDay.timeInDay?.startOfDay);
              newS.end = getTimeInDayBySeconds(specialDay.timeInDay?.endOfDay);
            } else {
              newS.start = 0;
              newS.end = 24 * 60 * 60;
            }
            newS.priority = 1;
            schedulesInDay.push(newS);
          }
        });
      }
    }

    // Kiểm tra Daily
    for (let j = 0; j <= SchedulesDaily.length; j++) {
      const s = { ...SchedulesDaily[j] };
      if (
        (!isEmpty(s) &&
          s.dailyData?.dateRange &&
          s.dailyData?.dateRange.dateFrom &&
          s.dailyData?.dateRange.dateTo &&
          date.isBetween(
            moment(s.dailyData?.dateRange.dateFrom, formatDate).startOf('day'),
            moment(s.dailyData?.dateRange.dateTo, formatDate).startOf('day'),
            undefined,
            '[]',
          )) ||
        (!isEmpty(s) && !s?.dailyData?.dateRange)
      ) {
        if (s.dailyData?.timeInDay?.startOfDay && s.dailyData?.timeInDay?.endOfDay) {
          s.start = getTimeInDayBySeconds(s.dailyData?.timeInDay?.startOfDay);
          s.end = getTimeInDayBySeconds(s.dailyData?.timeInDay?.endOfDay);
        } else {
          s.start = 0;
          s.end = 24 * 60 * 60;
        }
        s.priority = 2;
        s.priorityTimeRange = 1;
        if (s?.dailyData?.dateRange?.dateFrom && s.dailyData?.dateRange.dateTo) {
          s.priorityTimeRange = 2;
        }
        schedulesInDay.push(s);
      }
    }

    // Kiểm tra Weekly
    for (let j = 0; j <= SchedulesWeekly.length; j++) {
      const s = { ...SchedulesWeekly[j] };
      if (
        (!isEmpty(s) &&
          s.weeklyData?.dateRange &&
          s.weeklyData?.dateRange.dateFrom &&
          s.weeklyData?.dateRange.dateTo &&
          date.isBetween(
            moment(s.weeklyData?.dateRange.dateFrom, formatDate).startOf('day'),
            moment(s.weeklyData?.dateRange.dateTo, formatDate).startOf('day'),
            undefined,
            '[]',
          ) &&
          (!s.weeklyData?.daysOfWeek?.length || s.weeklyData?.daysOfWeek.includes(date.isoWeekday()))) ||
        (!s.weeklyData?.dateRange && s.weeklyData?.daysOfWeek.includes(date.isoWeekday()))
      ) {
        if (s.weeklyData?.timeInDay?.startOfDay && s.weeklyData?.timeInDay?.endOfDay) {
          s.start = getTimeInDayBySeconds(s.weeklyData?.timeInDay?.startOfDay);
          s.end = getTimeInDayBySeconds(s.weeklyData?.timeInDay?.endOfDay);
        } else {
          s.start = 0;
          s.end = 24 * 60 * 60;
        }
        s.priority = 3;
        s.priorityTimeRange = 1;
        if (s?.weeklyData?.dateRange?.dateFrom && s.weeklyData?.dateRange.dateTo) {
          s.priorityTimeRange = 2;
        }
        schedulesInDay.push(s);
      }
    }

    // Kiểm tra Monthly
    const month = date.get('month');
    for (let j = 0; j <= SchedulesMonthly.length; j++) {
      const s = { ...SchedulesMonthly[j] };
      if (
        !isEmpty(s) &&
        s.monthlyData?.months &&
        s.monthlyData?.months.length &&
        s.monthlyData?.months.map((month) => month.month).includes(month + 1) &&
        s.monthlyData?.months.find((m) => m.month === month + 1)?.days.includes(currentDate.date())
      ) {
        if (s.monthlyData?.timeInDay?.startOfDay && s.monthlyData?.timeInDay?.endOfDay) {
          s.start = getTimeInDayBySeconds(s.monthlyData?.timeInDay?.startOfDay);
          s.end = getTimeInDayBySeconds(s.monthlyData?.timeInDay?.endOfDay);
        } else {
          s.start = 0;
          s.end = 24 * 60 * 60;
        }
        s.priority = 4;
        schedulesInDay.push(s);
      }
    }

    schedulesInDay.sort((a: any, b: any) => a.start - b.start && a.priority - b.priority);
    let timeRange: number[] = [];
    // eslint-disable-next-line
    schedulesInDay.map((s: any) => {
      timeRange.push(s.start);
      timeRange.push(s.end);
    });

    timeRange = [...new Set(timeRange.sort((a: number, b: number) => a - b))];

    let schedulesByTimeRange = new Map();
    for (let j = 0; j <= timeRange.length; j++) {
      let array: any = [];
      // eslint-disable-next-line
      schedulesInDay.map((s: any) => {
        if (s.start <= timeRange[j] && s.end >= timeRange[j + 1]) {
          const newS = { ...s, start: timeRange[j], end: timeRange[j + 1] };
          array.push(newS);
        }
      });

      if (array.length > 0) {
        schedulesByTimeRange.set(
          `${timeRange[j]}-${timeRange[j + 1]}`,
          // eslint-disable-next-line
          array.sort((a: any, b: any) => {
            if (a.priority === b.priority) {
              return a.priorityTimeRange - b.priorityTimeRange;
            } else {
              return b.priority - a.priority;
            }
          })[array.length - 1],
        );
      }
    }
    schedulesInDay = [...schedulesByTimeRange].map(([name, value]) => value);
  }
  return schedulesInDay;
};

// Tính toán ra tất cả playlist sẽ được chạy trong ngày, phân theo khoảng thời gian
export const calculateSchedules = async (
  screenType: PlaylistScreenType,
  schedules: DevicePlaylistSchedule[],
  schedulesSeconds: DevicePlaylistSchedule[],
  scheduleVersion: number,
  formatDate: string,
) => {
  await clearSchedulesInYear();

  const currentDate = moment();
  const schedulesInDay = setScheduleInDay(schedules, formatDate, currentDate.dayOfYear(), currentDate);
  let schedulesInDaySecond = [];
  if (screenType === PlaylistScreenType.SplitScreen)
    schedulesInDaySecond = setScheduleInDay(schedulesSeconds, formatDate, currentDate.dayOfYear(), currentDate);
  await saveSchedulesInDay(
    [schedulesInDay, schedulesInDaySecond],
    currentDate.get('year'),
    currentDate.dayOfYear(),
    scheduleVersion,
  );
};

export const getFrameBackgroundStyle = (displayMode?: DisplayMode | null): string => {
  switch (displayMode) {
    case DisplayMode.Fill:
      return 'cover';
    case DisplayMode.Fit:
      return 'contain';
    default:
      return '100% 100%';
  }
};

export const getCurrentDateByTimezone = (timezone: string) => {
  return moment_tz().tz(timezone);
};

export const clearSchedulesInYear = async () => {
  await db.schedules.clear();
};

export const saveSchedulesInDay = async (
  data: any[],
  currentYear: number,
  dayOfYear: number,
  scheduleVersion: number,
) => {
  const schedules: any[] = [];
  const schedulesSecond: any[] = [];
  data[0].forEach((v: any) => schedules.push(v));
  data[1]?.forEach((v: any) => schedulesSecond.push(v));

  await db.schedules.add({
    day: dayOfYear,
    year: currentYear,
    schedules,
    schedulesSecond,
    scheduleVersion,
  });
};

export const getSchedulesInYear = async (currentDate: Moment) => {
  const currentYear = currentDate.get('year');
  const schedulesInYear = await db.schedules.limit(1).last();

  if (schedulesInYear && schedulesInYear?.year === currentYear) {
    return schedulesInYear;
  }

  return null;
};

export const getSchedulesInDay = async (currentDate: Moment) => {
  let schedulesInYear = await getSchedulesInYear(currentDate);
  if (schedulesInYear) {
    return [schedulesInYear.schedules, schedulesInYear.schedulesSecond];
  }
  return [];
};

export const getCurrentSchedules = async (
  currentDate: Moment,
): Promise<{
  firstScreenSchedule: DevicePlaylistSchedule | null;
  secondScreenSchedule?: DevicePlaylistSchedule | null;
  offScreen: boolean;
}> => {
  const currentSecondInDay =
    currentDate.get('hours') * 60 * 60 + currentDate.get('minutes') * 60 + currentDate.get('seconds');
  let schedulesInDay: any[] = (await getSchedulesInDay(currentDate)) as any[];
  const minutesOfScreen = env.minutesOfScreen ? Number(env.minutesOfScreen) : 5;
  let firstScreenSchedule: DevicePlaylistSchedule | null = null;
  let secondScreenSchedule: DevicePlaylistSchedule | null = null;
  let offScreenOne = false;
  let offScreenTwo = false;

  // console.log('current date is: ', currentDate.format());
  // console.log('currentSecondInDay: ', currentSecondInDay);

  if (schedulesInDay?.length) {
    // eslint-disable-next-line
    firstScreenSchedule = schedulesInDay[0].find((s: any) => {
      return s.start <= currentSecondInDay && s.end >= currentSecondInDay;
    });
    if (schedulesInDay[1]) {
      secondScreenSchedule = schedulesInDay[1].find((s: any) => {
        return s.start <= currentSecondInDay && s.end >= currentSecondInDay;
      });
    }

    if (!firstScreenSchedule) {
      const schedulesNext = schedulesInDay[0].find((s: any) => {
        return s.start > currentSecondInDay;
      });
      if (schedulesNext) {
        // Kiểm tra trong khoảng thời gian setting, nếu tiếp theo có playlist, thì không tắt màn hình,
        // tránh tình trạng màn hình bật/tắt liên tục khi các playlsit đặt giờ liền nhau,
        // và bật, tắt qua cec có thể không hoạt động đúng
        if (schedulesNext.start - currentSecondInDay > minutesOfScreen * 60) {
          firstScreenSchedule = null;
          offScreenOne = true;
        } else {
          firstScreenSchedule = null;
          offScreenOne = false;
        }
      } else {
        firstScreenSchedule = null;
        offScreenOne = true;
      }
    }
    if (!secondScreenSchedule && schedulesInDay[1]) {
      const schedulesNext = schedulesInDay[1].find((s: any) => {
        return s.start > currentSecondInDay;
      });
      if (schedulesNext) {
        // Kiểm tra trong khoảng thời gian setting, nếu tiếp theo có playlist, thì không tắt màn hình,
        // tránh tình trạng màn hình bật/tắt liên tục khi các playlsit đặt giờ liền nhau,
        // và bật, tắt qua cec có thể không hoạt động đúng
        if (schedulesNext.start - currentSecondInDay > minutesOfScreen * 60) {
          secondScreenSchedule = null;
          offScreenTwo = true;
        } else {
          secondScreenSchedule = null;
          offScreenTwo = false;
        }
      } else {
        secondScreenSchedule = null;
        offScreenTwo = true;
      }
    }
    return {
      firstScreenSchedule,
      secondScreenSchedule,
      offScreen: offScreenOne && offScreenTwo, // Khi cả 2 screen đều không có playlist thì mới off màn hình
    };
  } else {
    return {
      firstScreenSchedule,
      secondScreenSchedule,
      offScreen: true,
    };
  }
};

export async function calculateSchedulesData(
  screenType: PlaylistScreenType,
  schedules: any,
  schedulesSecond: any,
  version: number,
  formatDate: string,
) {
  await calculateSchedules(screenType, schedules, schedulesSecond, version, formatDate);
  const currentDate = moment();
  return getSchedulesInYear(currentDate);
}
