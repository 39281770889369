import { Device, ScheduleLayoutSettings } from '@/graphql/generated/graphql';
import { typeLanguage, TypeSidebar } from '@commons/Components/Sidebar';
import { atom } from 'recoil';

export const GLOBAL_STATE = 'GLOBAL_STATE';

export interface GlobalStateInterface {
  hashKey?: string;
  token?: string;
  deviceCode?: string;
  deviceCodeHash?: string;
  deviceVersion: number;
  currentDeviceObj: Device | null;
  layoutSettings?: ScheduleLayoutSettings;
  schedulesInDay?: any;
  currentSchedules: any[];

  visibleSideBar: boolean;
  isSelectedSideBar?: TypeSidebar;
  currentLang: typeLanguage;
  isSchedulesCalculated: boolean;
}

export const globalState = atom<GlobalStateInterface>({
  key: GLOBAL_STATE,
  default: {
    hashKey: undefined,
    token: undefined,
    deviceCode: undefined,
    deviceCodeHash: undefined,
    deviceVersion: 0,
    layoutSettings: undefined,
    schedulesInDay: undefined,
    currentSchedules: [],

    visibleSideBar: false,
    isSelectedSideBar: TypeSidebar.LANGUAGE,
    currentLang: typeLanguage.NOR,
    currentDeviceObj: null,
    isSchedulesCalculated: false,
  },
});
