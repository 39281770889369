import moment from 'moment';
import { customAlphabet } from 'nanoid/non-secure';
import { Device, DirectionRotateType, FrameDirection } from '../graphql/generated/graphql';
import { isJsonString } from './string';
var bcrypt = require('bcryptjs');

export const SCREEN_STATUS = 'SCREEN_STATUS';
const TIME_OFF_SCREEN = 'TIME_OFF_SCREEN';
const DEVICE_OBJECT = 'DEVICE_OBJECT';
const DEVICE_ID = 'DEVICE_ID';
const DEVICE_CODE_HASH = 'DEVICE_CODE_HASH';
const HASH_KEY = 'HASH_KEY';
const TIMEZONE = 'TIMEZONE';

let deviceObject: Device | null;
let token: string | null;

export enum ScreenStatus {
  ON = 'ON',
  OFF = 'OFF',
}

export interface SchedulesInYear {
  year: number;
  schedules: any;
}

export interface ScreenStatusLocal {
  status: ScreenStatus;
  firstTime?: boolean;
}

export const setTimezone = (timezone: string) => {
  localStorage.setItem(TIMEZONE, timezone);
};

export const getTimezone = () => {
  return localStorage.getItem(TIMEZONE);
};

export const removeDeviceObject = () => {
  localStorage.removeItem(DEVICE_OBJECT);
};

export const saveDeviceObject = (obj: Device | null) => {
  localStorage.setItem(DEVICE_OBJECT, JSON.stringify(obj || ''));
  deviceObject = obj;
  token = deviceObject?.token || null;
};

export const getDeviceObject = () => {
  const deviceStorage = parseObjectString(localStorage.getItem(DEVICE_OBJECT) || '');
  deviceObject = deviceStorage;
  return deviceStorage;
};

export const saveScreenStatus = (status: ScreenStatus, firstTime?: boolean, offByAdmin?: boolean) => {
  return localStorage.setItem(SCREEN_STATUS, JSON.stringify({ status: status, firstTime, offByAdmin }));
};

export const getScreenStatus = () => {
  const screenStatus: ScreenStatusLocal = parseObjectString(localStorage.getItem(SCREEN_STATUS) || '');
  return screenStatus;
};

export const getToken = () => {
  return token;
};

export const getDeviceCode = () => {
  return localStorage.getItem(DEVICE_ID);
};

export const getDeviceCodeHash = () => {
  return localStorage.getItem(DEVICE_CODE_HASH);
};

export const setHashKey = (hashKey: string) => {
  return localStorage.setItem(HASH_KEY, hashKey);
};

export const getHashKey = () => {
  return localStorage.getItem(HASH_KEY);
};

export const createDeviceCode = (): string => {
  let deviceCode = getDeviceCode();
  if (!deviceCode) {
    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 4);
    deviceCode = 'WS-' + moment().minutes() + moment().seconds() + nanoid().toUpperCase();
    localStorage.setItem(DEVICE_ID, deviceCode);
  }
  return deviceCode;
};

export const createDeviceCodeHash = (deviceCode: string, hashKey: string): string => {
  var salt = bcrypt.genSaltSync(2);
  var deviceCodeHash = bcrypt.hashSync(hashKey + deviceCode, salt);
  localStorage.setItem(DEVICE_CODE_HASH, deviceCodeHash);
  return deviceCodeHash;
};

export const getObjDevice = () => {
  return deviceObject;
};

export const parseObjectString = (str: string) => {
  if (isJsonString(str)) {
    const Object = JSON.parse(str);
    return Object;
  }
  return null;
};

//timeOffScreen
// thời gian có thể tắt màn hình nếu không có playlist sau khi admin bật màn hình
export const saveTimeOffScreen = (time: string) => {
  return localStorage.setItem(TIME_OFF_SCREEN, time);
};

export const getTimeOffScreen = () => {
  return localStorage.getItem(TIME_OFF_SCREEN);
};

export const removeTimeOffScreen = () => {
  localStorage.removeItem(TIME_OFF_SCREEN);
};

export const getTargetScreenDirection = (
  layoutOrientation: FrameDirection,
  targetDirection: DirectionRotateType,
): DirectionRotateType => {
  if (layoutOrientation === FrameDirection.Horizontal) {
    if (targetDirection === DirectionRotateType.Normal) {
      return DirectionRotateType.Normal;
    } else {
      return DirectionRotateType.Inverted;
    }
  } else {
    if (targetDirection === DirectionRotateType.Normal) {
      return DirectionRotateType.Right;
    } else {
      return DirectionRotateType.Left;
    }
  }
};
