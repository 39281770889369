import React, { useEffect } from 'react';
import './style.scss';
import logo from '../../assets/images/logo.png';
import { getHistory } from '@helpers/history';

const SplashIndex = () => {
  useEffect(() => {
    const timeOut = setTimeout(() => {
      getHistory().push('/home');
    }, 3000);

    return () => clearTimeout(timeOut);
  }, []);

  return (
    <div id="SplashScreen" className="h-100 w-100">
      <div className="bg-white-opacity-9 w-100 h-100 position-absolute d-flex justify-content-center align-items-center ">
        <img width={400} src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default SplashIndex;
