import { Device } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { saveDeviceObject } from '@helpers/device_code';
import { globalState } from '@modules/state';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { NotificationError, NotificationSuccess } from '../../../commons/Components/Notification/index';
import { getSDK } from '../../../graphql/client';

const GET_DEVICE_DETAIL = 'GET_DEVICE_DETAIL';

export default function useCreateDevice(deviceCode?: string, deviceCodeHash?: string) {
  const setAppState = useSetRecoilState(globalState);
  return useQuery(
    [GET_DEVICE_DETAIL, deviceCode, deviceCodeHash],
    async () => {
      return getSDK().getPublicDeviceByCode({ input: { deviceCode, deviceCodeHash } });
    },
    {
      enabled: !!deviceCode && !!deviceCodeHash,
      onSuccess: (res) => {
        if (res && res.publicGetDeviceByCode && res.publicGetDeviceByCode?.token) {
          const token = res.publicGetDeviceByCode.token;
          // Lưu thông tin device dưới localStorage, và cập nhật token mỗi khi call api
          saveDeviceObject(res.publicGetDeviceByCode as Device);
          setAppState((state) => ({ ...state, token }));
          console.log('useCreateDevice');
          NotificationSuccess(i18n.t('success'), i18n.t('new_data_loaded'));
        } else {
          NotificationError(i18n.t('error'), i18n.t('no_device_matched'));
          saveDeviceObject(null);
        }
      },
      onError: () => {
        NotificationError(i18n.t('error'), i18n.t('request_fail'));
      },
    },
  );
}
