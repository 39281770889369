import env from '@/env';
import { useMutation } from 'react-query';
import useSendWifiDevice from './useSendWifiDevice';

export default function useGetListWifi() {
  const { SendWifi } = useSendWifiDevice();

  return useMutation(async () => {
    let postRoute = `${env.piApiEndPoint}/list-wifi`;
    const rawResponse = await fetch(postRoute, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const listWifi = await rawResponse.json();
    if (listWifi) {
      SendWifi({
        listWifi: listWifi.map((wifi: any) => {
          return {
            ssid: wifi.ssid,
            signalLevel: Number(wifi.signalLevel),
            encryptMode: wifi.encryptionMode ? wifi.encryptionMode : 'off',
          };
        }),
      });
    }
    return listWifi;
  });
}
