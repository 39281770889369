import env from '@/env';
import { createDeviceCode, createDeviceCodeHash } from '@helpers/device_code';
import { globalState } from '@modules/state';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { CacheRoute } from './useOfflinePlaylist';

const GET_HASH_KEY = 'GET_HASH_KEY';

export default function useGetHashKey(hashKey?: string) {
  const setAppState = useSetRecoilState(globalState);

  return useQuery(
    [GET_HASH_KEY],
    async () => {
      const getRoute = `${env.piApiEndPoint}/${CacheRoute.GET_HASH_KEY}`;
      const response = await fetch(getRoute, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data?.key) {
        // tạo deviceCode
        const deviceCode = createDeviceCode();
        const deviceCodeHash = createDeviceCodeHash(deviceCode, data.key);
        setAppState((state) => ({ ...state, hashKey: data.key, deviceCode, deviceCodeHash }));
        return data?.key;
      }
      return null;
    },
    {
      enabled: !hashKey,
      refetchInterval: 2000,
      refetchIntervalInBackground: hashKey ? false : true,
    },
  );
}
