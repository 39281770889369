import useOfflinePlaylist from '@hooks/useOfflinePlaylist';
import { FrameType } from '@/graphql/generated/graphql';
import FrameDocument from '@commons/Components/FrameDocument';
import IFrameVideo from '@commons/Components/IframeVideo';
import IframeWebUrl from '@commons/Components/IframeWebUrl';
import { globalState } from '@modules/state';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getFrameBackgroundStyle } from '../helpers/check-schedule';

export interface SizeInterface {
  width: number;
  height: number;
  ratio: number;
  screenWidth: number;
  screenHeight: number;
}

interface IProps {
  index: number;
  item: any;
  slideActive: number;
  size: SizeInterface;
  playlistId: string;
  onVideoEnd?(): void;
}

function FrameComponent(props: IProps) {
  const { onGetLinkFromCache } = useOfflinePlaylist();
  const appState = useRecoilValue(globalState);

  const otherFrame = (item: any, frameType: FrameType, FrameIndex: Number) => {
    switch (frameType) {
      case FrameType.Image:
        const url = onGetLinkFromCache(props.playlistId, item.image?.url);
        return (
          <div
            style={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: getFrameBackgroundStyle(appState?.layoutSettings?.displayMode),
              backgroundPosition: 'center',
              width: '100%',
              height: '100%',
            }}
          />
        );
      // return url ? <img key={`IMAGE_FRAME_${FrameIndex}`} style={styleItemFrame} alt="" src={url} /> : null;
      case FrameType.WebUrl:
        return <IframeWebUrl key={`WEB_URL_FRAME_${FrameIndex}`} src={item.webUrl} indexFrame={FrameIndex} />;
    }
  };

  const renderFrame = (item: any, index: number, styleItemFrame?: any) => {
    switch (item.type) {
      case FrameType.Document:
        let documentSrc = onGetLinkFromCache(props.playlistId, item.url);
        return (
          <FrameDocument
            isActive={props.slideActive === index ? true : false}
            key={`DOCUMENT_FRAME_${index}`}
            src={documentSrc}
            indexFrame={index}
            duration={item.duration}
            styleFrameContainer={styleItemFrame}
            displayMode={appState?.layoutSettings?.displayMode}
          />
        );

      case FrameType.Video:
        let videoSrc = onGetLinkFromCache(props.playlistId, item?.videoProfiles[0].url);
        let poster = onGetLinkFromCache(props.playlistId, item?.documentImages[0].url);
        return (
          <IFrameVideo
            isActive={props.slideActive === index ? true : false}
            key={`DOCUMENT_FRAME_${index}`}
            src={videoSrc}
            onEnd={props.onVideoEnd}
            indexFrame={index}
            poster={poster || ''}
          />
        );

      default:
        return <>{otherFrame(item, item.type, index)}</>;
    }
  };

  return props.item ? renderFrame(props.item, props.index) : null;
}

export default React.memo(FrameComponent);
