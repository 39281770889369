import { Frame, FrameType } from '@/graphql/generated/graphql';
import { globalState } from '@modules/state';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useRecoilValue } from 'recoil';
import FrameComponent, { SizeInterface } from './FrameComponent';
import NoPlaylist from './NoPlaylist';

interface IProps {
  frames?: Frame[];
  size: SizeInterface;
  playlistId: string;
  version?: number;
}

function PlaylistSlider(props: IProps) {
  const appState = useRecoilValue(globalState);
  const [slideActiveFrame, setSlideActiveFrame] = useState<number>(0);
  const [slideActiveDuration, setSlideActiveDuration] = useState<number>(4000);
  const ref = useRef(null);

  let frames = props.frames;
  frames = frames?.filter((frame: Frame) => {
    let canActive = true;
    if (!frame.availability?.enabled) canActive = false;
    const timezone = appState.currentDeviceObj?.group?.timezone;
    if (!timezone) canActive = false;
    else {
      if (
        frame.availability?.fromDate &&
        moment().tz(timezone).isBefore(moment(frame.availability?.fromDate).tz(timezone))
      ) {
        canActive = false;
      }
      if (
        frame.availability?.toDate &&
        moment().tz(timezone).isAfter(moment(frame.availability?.toDate).tz(timezone))
      ) {
        canActive = false;
      }
    }
    return canActive;
  });
  if (frames?.length === 1 && frames[0].type === FrameType.Video) {
    frames = frames.concat(frames);
  }

  const settings = {
    speed: 500,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: true,
    swipe: false,
    pauseOnHover: false,
    swipeToSlide: false,
    useTransform: false,
    autoplaySpeed: slideActiveDuration,
    beforeChange: (current: any, next: any) => {
      setSlideActiveFrame(next);
    },
  };

  useEffect(() => {
    if (props?.frames && props?.frames[slideActiveFrame]) {
      const duration = props?.frames[slideActiveFrame].duration || 20;
      const isVideo = props?.frames[slideActiveFrame].type === FrameType.Video;
      setSlideActiveDuration(!isVideo ? duration * 1000 : duration * 1500);
    }
    // eslint-disable-next-line
  }, [slideActiveFrame, props.playlistId]);

  const handleVideoEnd = () => {
    (ref.current as any).slickNext();
  };

  return frames?.length ? (
    <Slider {...settings} ref={ref}>
      {frames.map((itemFrame: Frame, index: any) => {
        return (
          <div key={index} className="position-relative h-100">
            <FrameComponent
              playlistId={props.playlistId}
              item={itemFrame}
              index={index}
              slideActive={slideActiveFrame}
              size={props.size}
              onVideoEnd={handleVideoEnd}
            />
          </div>
        );
      })}
    </Slider>
  ) : (
    <NoPlaylist />
  );
}

export default React.memo(PlaylistSlider, (prevProps: IProps, nextProps: IProps) => {
  return (
    prevProps.playlistId === nextProps.playlistId &&
    prevProps.version === nextProps.version &&
    prevProps.size.ratio === nextProps.size.ratio
  );
});
