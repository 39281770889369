import { StatusConnectWifiInput } from '@/graphql/generated/graphql';
import { getSDK } from '../graphql/client';

export default function useStatusConnectWifi() {
  const sendWifiConnectionStatus = async (input: StatusConnectWifiInput) => {
    try {
      await getSDK().sendWifiConnectionStatus({ input });
    } catch (error) {
      console.log('error: ', error);
    }
  };
  return { sendWifiConnectionStatus };
}
