import { useEffect } from 'react';
import Peer from 'peerjs';
import io from 'socket.io-client';
import env from '@/env';

const socket = io(env.webrtcSignalingServer);
const iceServer: RTCConfiguration = {
  iceServers: [
    { urls: env.webrtcStunServer.split(',') },
    {
      username: env.webrtcTurnUsername,
      credential: env.webrtcTurnCredential,
      urls: env.webrtcTurnServer.split(','),
    },
  ],
  iceTransportPolicy: 'all',
};
const PEERJS_CONFIG = {
  config: iceServer,
  path: '/peerjs',
  host: new URL(env.webrtcSignalingServer).host,
  secure: true,
};

export default function useRecordListener(deviceCode?: string) {
  useEffect(() => {
    if (!deviceCode) return;

    socket.emit('pi-register', deviceCode);
    // socket.on('start-nego', (senderId) => {
    //   console.log('start nego connection with ', senderId);

    //   onRequestScreenCapture(senderId);
    // });
    socket.on('cms-waiting-call', async (id) => {
      const peer = new Peer(PEERJS_CONFIG);
      console.log('start call ', id);
      //@ts-ignore
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      peer.on('open', () => {
        peer.call(id, stream);
        socket.on('cms-closed', (id) => {
          // console.log(' cms cloed', id);

          stream.getTracks().forEach((t: MediaStreamTrack) => t.stop());
        });
      });
    });

    // eslint-disable-next-line
  }, [deviceCode]);
}
