import { useTranslation } from 'react-i18next';
import LoadingCpn from '../../../commons/Components/Loading/index';

interface IProps {
  size: any;
  playlistFromCache: any;
}

export default function LoadingCacheProgress(props: IProps) {
  const { t } = useTranslation(['translation']);

  return (
    <div className="no_connect h-100 w-100">
      <div className="bg-opacity-7 w-100 h-100 position-absolute d-flex flex-column justify-content-center align-items-center  py-4">
        <LoadingCpn type="inline" sizeSpin="large" style={{ transform: 'scale(2)' }} />
        <h1 className="text-loading without-loading mt-4 text-center">
          {t('playlist_is_downloading')}
          <span
            className={`text-loading ${
              props.size.screenWidth > props.size.screenHeight ? 'fs_54-horizontal' : 'fs_54-vertical '
            }`}
            data-text="..."
          >
            ...
          </span>
        </h1>
        {props.playlistFromCache && !props.playlistFromCache.isReady && props.playlistFromCache.failUrls.length > 0 && (
          <span
            className={`text-error mt-3  ${
              props.size.screenWidth > props.size.screenHeight ? 'fs_30-horizontal' : 'fs_30-vertical '
            }`}
          >
            {t('download_error')}
          </span>
        )}
      </div>
    </div>
  );
}
