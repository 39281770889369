import type { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JsonScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AdminDeviceScreenStatus {
  Auto = 'AUTO',
  Off = 'OFF',
  On = 'ON'
}

export type AssignUserInput = {
  companyId: Scalars['ID'];
  role: Roles;
  /** List user's id for assign  */
  userIds: Array<Scalars['ID']>;
};

export type Availability = {
  __typename?: 'Availability';
  enabled?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['Float']>;
};

export type AvailabilityInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['Float']>;
};

export type BlockUserInput = {
  _id: Scalars['ID'];
  status: User_Status;
};

export enum Capacity {
  Fifty = 'FIFTY',
  Hundred = 'HUNDRED',
  Ten = 'TEN',
  Twenty = 'TWENTY'
}

export type CaptureScreenInput = {
  file: Scalars['String'];
};

export type ChangePassRess = {
  __typename?: 'ChangePassRess';
  token?: Maybe<Scalars['String']>;
  updated: Scalars['Boolean'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  /** id */
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  admins?: Maybe<Array<User>>;
  capacity: Scalars['Float'];
  createdAt: Scalars['Float'];
  createdBy: User;
  createdById: Scalars['ID'];
  /** Dung lượng file thực tế đã upload (MB) */
  currentStorageSize?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy: Scalars['ID'];
  deletedByUser: User;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  /** Dung lượng file có thể upload (MB) */
  limitUpload?: Maybe<Scalars['Float']>;
  managerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTime>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  userLimit: Scalars['Float'];
  userRole?: Maybe<Roles>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  /** id */
  _id: Scalars['ID'];
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** full name */
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Roles;
  status: User_Status;
  tokenExpired?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  username: Scalars['String'];
};

export type ConnectWifiInput = {
  _id: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  ssid: Scalars['String'];
};

export type CreateCompanyInput = {
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Capacity>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  limitUpload: Scalars['Float'];
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTimeInput>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  userLimit?: Maybe<UserLimit>;
};

export type CreateDeviceInput = {
  companyId?: Maybe<Scalars['ID']>;
  deviceCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  os?: Maybe<OsType>;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  screen?: Maybe<ScreenType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type CreateFolderInput = {
  filename: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type CreateGroupInput = {
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreatePlaylistInput = {
  companyId: Scalars['ID'];
  frames: Array<FrameInputType>;
  name: Scalars['String'];
};

export type CreateTokenAutoLoginInput = {
  companyId: Scalars['String'];
};

export type CreateUploadSettingInput = {
  key: SettingType;
  value: Array<UploadSettingDataInput>;
};

export type CreateUserInput = {
  companyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
  username: Scalars['String'];
};

export type DailyScheduleData = {
  __typename?: 'DailyScheduleData';
  dateRange?: Maybe<DateRange>;
  timeInDay?: Maybe<TimeInDay>;
};

export type DailyScheduleInputData = {
  dateRange?: Maybe<DateRangeInput>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** Time start */
  dateFrom: Scalars['String'];
  /** Time end */
  dateTo: Scalars['String'];
};

export type DateRangeInput = {
  /** Time start */
  dateFrom: Scalars['String'];
  /** Time end */
  dateTo: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DayOff = {
  __typename?: 'DayOff';
  /** id */
  _id: Scalars['ID'];
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
};

export type DeleteCompanyInput = {
  _id: Scalars['String'];
};

export type DeleteDeviceInput = {
  _id: Scalars['ID'];
};

export type DeleteGroupInput = {
  _id: Scalars['ID'];
};

export type DeleteMediaInput = {
  _id: Scalars['ID'];
};

export type DeletePlaylistInput = {
  _id: Scalars['ID'];
};

export type Device = {
  __typename?: 'Device';
  /** id */
  _id: Scalars['ID'];
  adminScreenStatus?: Maybe<AdminDeviceScreenStatus>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  currentPlaylist?: Maybe<Playlist>;
  /** field cũ, sử dụng currentPlaylistIds thay thế */
  currentPlaylistId?: Maybe<Scalars['ID']>;
  currentPlaylistIds?: Maybe<Array<Scalars['String']>>;
  currentPlaylists?: Maybe<Array<Playlist>>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** match với mã trên thiết bị */
  deviceCode?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionRotateType>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  /** Không dùng đến, dùng trường lastGetInfoAt thay thế */
  lastConnect?: Maybe<Scalars['String']>;
  lastGetInfoAt?: Maybe<Scalars['Float']>;
  lastScreenshot?: Maybe<Scalars['String']>;
  localTime?: Maybe<Scalars['String']>;
  /** Địa chỉ mac của thiết bị thật */
  macAddress?: Maybe<Scalars['String']>;
  momentDateFormat?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offByAdmin?: Maybe<Scalars['Boolean']>;
  os?: Maybe<OsType>;
  /** Chuyển schedule sang group device */
  schedules?: Maybe<Array<DevicePlaylistSchedule>>;
  screen?: Maybe<ScreenType>;
  screenStatus?: Maybe<DeviceScreenStatus>;
  status?: Maybe<DeviceStatus>;
  subscriber?: Maybe<SubscriberType>;
  subscriptionDate?: Maybe<SubscriptionDate>;
  /** Device token sinh ra khi api publicGetDeviceByCode được gọi (device đc gán vào 1 thiết bị thật) */
  token?: Maybe<Scalars['String']>;
  /** Bỏ field này */
  uniqueCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Float']>;
  wifi?: Maybe<WifiType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type DevicePlaylistSchedule = {
  __typename?: 'DevicePlaylistSchedule';
  createdById?: Maybe<Scalars['String']>;
  dailyData?: Maybe<DailyScheduleData>;
  monthlyData?: Maybe<MonthlyScheduleData>;
  playlist?: Maybe<Playlist>;
  playlistId: Scalars['ID'];
  specialDayData?: Maybe<Array<SpecialDayScheduleData>>;
  type: ScheduleType;
  weeklyData?: Maybe<WeeklyScheduleData>;
};

export type DevicePlaylistScheduleInput = {
  createdById: Scalars['String'];
  dailyData?: Maybe<DailyScheduleInputData>;
  monthlyData?: Maybe<MonthlyScheduleInputData>;
  playlistId: Scalars['ID'];
  specialDayData?: Maybe<Array<SpecialDayScheduleInputData>>;
  type: ScheduleType;
  weeklyData?: Maybe<WeeklyScheduleInputData>;
};

export type DevicePubSub = {
  __typename?: 'DevicePubSub';
  data: Scalars['JsonScalar'];
  type: DevicePubSubType;
};

export enum DevicePubSubType {
  AdminForceUpdateScreenStatus = 'ADMIN_FORCE_UPDATE_SCREEN_STATUS',
  CaptureScreen = 'CAPTURE_SCREEN',
  ClearDeviceStorage = 'CLEAR_DEVICE_STORAGE',
  ConnectWifi = 'CONNECT_WIFI',
  Deleted = 'DELETED',
  DeviceCodeUpdated = 'DEVICE_CODE_UPDATED',
  NewCaptureScreen = 'NEW_CAPTURE_SCREEN',
  NewData = 'NEW_DATA',
  ReloadClientApp = 'RELOAD_CLIENT_APP',
  RestartDevice = 'RESTART_DEVICE',
  RotateScreen = 'ROTATE_SCREEN',
  ScanWifiDevice = 'SCAN_WIFI_DEVICE',
  SendGroupMessage = 'SEND_GROUP_MESSAGE',
  SendWifi = 'SEND_WIFI',
  StatusConnectWifi = 'STATUS_CONNECT_WIFI'
}

export enum DeviceScreenStatus {
  Off = 'OFF',
  On = 'ON'
}

export enum DeviceStatus {
  LostConnection = 'LOST_CONNECTION',
  NoConnection = 'NO_CONNECTION',
  NoPlaylist = 'NO_PLAYLIST',
  Off = 'OFF',
  On = 'ON'
}

export enum DirectionRotateType {
  Inverted = 'INVERTED',
  Left = 'LEFT',
  Normal = 'NORMAL',
  Right = 'RIGHT'
}

export enum DisplayMode {
  Actual = 'ACTUAL',
  Fill = 'FILL',
  Fit = 'FIT',
  Stretch = 'STRETCH'
}

export enum FileType {
  File = 'FILE',
  Folder = 'FOLDER'
}

export type ForceUpdateScreenStatusInput = {
  _id: Scalars['ID'];
  status: AdminDeviceScreenStatus;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type Frame = {
  __typename?: 'Frame';
  availability?: Maybe<Availability>;
  /** chứa ảnh của video hoặc document đã qua xử lý sau khi playlist được tạo */
  documentImages?: Maybe<Array<MediaImage>>;
  duration: Scalars['Float'];
  /** chứa thông tin image đã qua xử lý sau khi playlist được tạo */
  image?: Maybe<MediaImage>;
  media?: Maybe<Media>;
  /** Phòng trường hợp media bị xóa thì khi update playlist có thể check và bỏ frame này khỏi list */
  mediaId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  type: FrameType;
  /** chứa video profiles đã qua xử lý sau khi playlist được tạo */
  videoProfiles?: Maybe<Array<VideoProfile>>;
  webUrl?: Maybe<Scalars['String']>;
};

export enum FrameDirection {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL'
}

export type FrameInputType = {
  availability?: Maybe<AvailabilityInput>;
  duration: Scalars['Float'];
  media?: Maybe<MediaInput>;
  /** Phòng trường hợp media bị xóa thì khi update playlist có thể check và bỏ frame này khỏi list */
  mediaId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  type: FrameType;
  webUrl?: Maybe<Scalars['String']>;
};

export enum FrameType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebUrl = 'WEB_URL'
}

export type Group = {
  __typename?: 'Group';
  /** id */
  _id: Scalars['ID'];
  approvedById?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  devices?: Maybe<Array<Device>>;
  editingById?: Maybe<Scalars['ID']>;
  layoutSettings?: Maybe<ScheduleLayoutSettings>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  /** Khởi động lại thiết bị hàng ngày */
  rebootDeviceEveryDay?: Maybe<Scalars['Boolean']>;
  rejectedById?: Maybe<Scalars['ID']>;
  scheduleVersion?: Maybe<Scalars['Float']>;
  schedules?: Maybe<Array<DevicePlaylistSchedule>>;
  schedulesSecond?: Maybe<Array<DevicePlaylistSchedule>>;
  showClock?: Maybe<Scalars['Boolean']>;
  status?: Maybe<GroupStatus>;
  timezone: Scalars['String'];
  /** Dừng chạy lịch, (dừng chạy tạm thời, không phải xóa) */
  turnOffSchedule?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
};

export enum GroupMessageDirection {
  LeftToRight = 'LEFT_TO_RIGHT',
  RightToLeft = 'RIGHT_TO_LEFT'
}

export enum GroupMessageSpeed {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Slow = 'SLOW'
}

export enum GroupStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  WaitToApprove = 'WAIT_TO_APPROVE'
}

export type Jwt = {
  __typename?: 'JWT';
  companyId?: Maybe<Scalars['String']>;
  expiresAt: Scalars['Int'];
  isSuperAdmin: Scalars['Boolean'];
  payload: User;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Int'];
  role: Roles;
  token: Scalars['String'];
};


export type KickOutCompanyInput = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  _id: Scalars['ID'];
  canEdit: Scalars['Boolean'];
  clanIds?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Float']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** ảnh documents, ảnh video sau khi tạo playlist */
  images?: Maybe<Array<MediaImage>>;
  /** aws transcode jobId */
  jobId?: Maybe<Scalars['String']>;
  mediaProcess?: Maybe<Media>;
  mediaType?: Maybe<MediaType>;
  metadata?: Maybe<Scalars['JsonScalar']>;
  mimetype?: Maybe<Scalars['String']>;
  optimizePath?: Maybe<Scalars['String']>;
  /** Link sau khi convert/optimize */
  optimizeUrl?: Maybe<Scalars['String']>;
  /** Link ban đầu */
  originalUrl?: Maybe<Scalars['String']>;
  parent?: Maybe<Media>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  /** Worker process retries time */
  retry?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  status: MediaStatus;
  /** Ảnh thumbs */
  thumbs?: Maybe<Array<MediaThumb>>;
  /** file or folder */
  type: FileType;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  /** video profile sau khi tạo playlist */
  videoProfiles?: Maybe<Array<VideoProfile>>;
  width?: Maybe<Scalars['Int']>;
};

export enum MediaFilterType {
  Image = 'IMAGE',
  Pdf = 'PDF',
  Powerpoint = 'POWERPOINT',
  Video = 'VIDEO'
}

export type MediaImage = {
  __typename?: 'MediaImage';
  height?: Maybe<Scalars['Float']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaImageInput = {
  height?: Maybe<Scalars['Float']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaInput = {
  _id: Scalars['ID'];
  canEdit?: Maybe<Scalars['Boolean']>;
  clanIds?: Maybe<Array<Scalars['ID']>>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdById?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Float']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** ảnh documents, ảnh video */
  images?: Maybe<Array<MediaImageInput>>;
  jobId?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MediaType>;
  metadata?: Maybe<Scalars['JsonScalar']>;
  mimetype?: Maybe<Scalars['String']>;
  optimizePath?: Maybe<Scalars['String']>;
  /** Link sau khi convert/optimize */
  optimizeUrl?: Maybe<Scalars['String']>;
  /** Link ban đầu */
  originalUrl?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  status?: Maybe<MediaStatus>;
  thumbs?: Maybe<Array<MediaThumbInput>>;
  /** file or folder */
  type: FileType;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedById?: Maybe<Scalars['ID']>;
  videoProfiles?: Maybe<Array<VideoProfileInput>>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaOrderBy = {
  direction: Sort;
  field: MediaOrderField;
};

export enum MediaOrderField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Type = 'TYPE'
}

export enum MediaStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Processing = 'PROCESSING'
}

export type MediaThumb = {
  __typename?: 'MediaThumb';
  path: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type MediaThumbInput = {
  path: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export enum MediaType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Pdf = 'PDF',
  Powerpoint = 'POWERPOINT',
  Video = 'VIDEO'
}

export type MonthInYear = {
  __typename?: 'MonthInYear';
  days: Array<Scalars['Int']>;
  month: Scalars['Int'];
};

export type MonthInYearInput = {
  days: Array<Scalars['Int']>;
  month: Scalars['Int'];
};

export type MonthlyScheduleData = {
  __typename?: 'MonthlyScheduleData';
  months?: Maybe<Array<MonthInYear>>;
  timeInDay?: Maybe<TimeInDay>;
};

export type MonthlyScheduleInputData = {
  months: Array<MonthInYearInput>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type MoveMediaInput = {
  _id: Scalars['ID'];
  parentId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminForceUpdateScreenStatus?: Maybe<Device>;
  approveAllGroup: Scalars['Boolean'];
  assignUserToCompany: Scalars['Float'];
  blockUser?: Maybe<User>;
  captureScreenshot: Scalars['Boolean'];
  changePassword: ChangePassRess;
  clearDeviceStorage: Scalars['Boolean'];
  connectWifiDevice?: Maybe<Scalars['Boolean']>;
  createCompany?: Maybe<Company>;
  createDevice?: Maybe<Device>;
  createFolder?: Maybe<Media>;
  createGroup?: Maybe<Group>;
  createPlaylist?: Maybe<Playlist>;
  createTokenAutoLogin: NewTokenAutoLogin;
  createUploadSetting?: Maybe<Setting>;
  createUser?: Maybe<User>;
  delPlaylist: Scalars['Boolean'];
  deleteCompany?: Maybe<Scalars['Boolean']>;
  deleteDevice: Scalars['Boolean'];
  deleteFile?: Maybe<Scalars['Boolean']>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  forgotPassword: Scalars['Boolean'];
  kickOutCompany: Scalars['Boolean'];
  login: Jwt;
  logout: Scalars['Boolean'];
  moveFile?: Maybe<Media>;
  publicUpdateDeviceInfo?: Maybe<Device>;
  publicUpdateDeviceScreenStatus?: Maybe<Device>;
  publicUpdateDeviceStatus?: Maybe<Device>;
  publicUpdateRotateScreen: Scalars['Boolean'];
  refreshToken: Jwt;
  rejectAllGroup: Scalars['Boolean'];
  reloadClientApp: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPasswordUser: Scalars['Boolean'];
  restartDevice: Scalars['Boolean'];
  restoreUser?: Maybe<Scalars['Boolean']>;
  rotateScreen: Device;
  scanWifiDevice?: Maybe<Array<WifiType>>;
  sendGroupMessage: Scalars['Boolean'];
  sendScreenshot?: Maybe<Scalars['Boolean']>;
  sendWifiDevice?: Maybe<Scalars['Boolean']>;
  settingDevice?: Maybe<Device>;
  statusConnectWifi?: Maybe<Scalars['Boolean']>;
  updateCompany?: Maybe<Company>;
  updateDevice?: Maybe<Device>;
  updateDeviceCurrentPlaylists?: Maybe<Device>;
  updateDeviceScreenStatus?: Maybe<Device>;
  updateDeviceStatus?: Maybe<Device>;
  updateFile?: Maybe<Media>;
  updateGroup?: Maybe<Group>;
  updateGroupPlaylistSchedules?: Maybe<Group>;
  updateGroupStatus?: Maybe<Group>;
  updatePlaylist?: Maybe<Playlist>;
  updatePlaylistGroup: Scalars['Boolean'];
  updatePlaylistToOtherGroup: Scalars['Boolean'];
  updateProfile: User;
  updateTimeZoneGroup?: Maybe<Group>;
  updateUser?: Maybe<User>;
  uploadFile?: Maybe<Media>;
  verifyTokenAutoLogin: Jwt;
};


export type MutationAdminForceUpdateScreenStatusArgs = {
  input: ForceUpdateScreenStatusInput;
};


export type MutationAssignUserToCompanyArgs = {
  assignUserInput: AssignUserInput;
};


export type MutationBlockUserArgs = {
  input: BlockUserInput;
};


export type MutationCaptureScreenshotArgs = {
  input: QueryDeviceInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationClearDeviceStorageArgs = {
  input: QueryDeviceInput;
};


export type MutationConnectWifiDeviceArgs = {
  input: ConnectWifiInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};


export type MutationCreateTokenAutoLoginArgs = {
  input: CreateTokenAutoLoginInput;
};


export type MutationCreateUploadSettingArgs = {
  input: CreateUploadSettingInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDelPlaylistArgs = {
  input: DeletePlaylistInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteMediaInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteUserArgs = {
  input: QueryUserInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationKickOutCompanyArgs = {
  input: KickOutCompanyInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLogoutArgs = {
  input: Scalars['String'];
};


export type MutationMoveFileArgs = {
  input: MoveMediaInput;
};


export type MutationPublicUpdateDeviceInfoArgs = {
  input: PublicUpdateDeviceInfoInput;
};


export type MutationPublicUpdateDeviceScreenStatusArgs = {
  input: PublicUpdateDeviceScreenStatusInput;
};


export type MutationPublicUpdateDeviceStatusArgs = {
  input: PublicUpdateDeviceStatusInput;
};


export type MutationPublicUpdateRotateScreenArgs = {
  input: PublicUpdateRotateInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationReloadClientAppArgs = {
  input: QueryDeviceInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordUserArgs = {
  input: ResetPasswordUserInput;
};


export type MutationRestartDeviceArgs = {
  input: QueryDeviceInput;
};


export type MutationRestoreUserArgs = {
  input: QueryUserInput;
};


export type MutationRotateScreenArgs = {
  input: UpdateRotateInput;
};


export type MutationScanWifiDeviceArgs = {
  input: ScanWifiDeviceInput;
};


export type MutationSendGroupMessageArgs = {
  input: SendGroupMessageInput;
};


export type MutationSendScreenshotArgs = {
  input: CaptureScreenInput;
};


export type MutationSendWifiDeviceArgs = {
  input: SendWifiInput;
};


export type MutationSettingDeviceArgs = {
  input: SettingDeviceInput;
};


export type MutationStatusConnectWifiArgs = {
  input: StatusConnectWifiInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceCurrentPlaylistsArgs = {
  input: UpdateDeviceCurrentPlaylistsInput;
};


export type MutationUpdateDeviceScreenStatusArgs = {
  input: UpdateDeviceStatusInput;
};


export type MutationUpdateDeviceStatusArgs = {
  input: UpdateDeviceStatusInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateMediaInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateGroupPlaylistSchedulesArgs = {
  input: UpdateDeviceSchedulesInput;
};


export type MutationUpdateGroupStatusArgs = {
  input: UpdateGroupStatusInput;
};


export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};


export type MutationUpdatePlaylistGroupArgs = {
  input: SetPlaylistToGroup;
};


export type MutationUpdatePlaylistToOtherGroupArgs = {
  input: SetPlaylistToOtherGroup;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateTimeZoneGroupArgs = {
  input: UpdateTimeZoneGroupInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  parentId?: Maybe<Scalars['ID']>;
};


export type MutationVerifyTokenAutoLoginArgs = {
  input: VerifyTokenAutoLoginInput;
};

export type NewTokenAutoLogin = {
  __typename?: 'NewTokenAutoLogin';
  token: Scalars['String'];
};

export enum OsType {
  Android = 'ANDROID',
  Linux = 'LINUX',
  Tizen = 'TIZEN',
  Webos = 'WEBOS'
}

export type OpenTime = {
  __typename?: 'OpenTime';
  /** Time close */
  closeTime: Scalars['Float'];
  /** Day */
  day: DayOfWeek;
  /** is open time */
  isOpen: Scalars['Boolean'];
  /** Time open */
  openTime: Scalars['Float'];
};

export type OpenTimeInput = {
  /** Time close */
  closeTime: Scalars['Float'];
  /** Day */
  day: DayOfWeek;
  /** is open time */
  isOpen: Scalars['Boolean'];
  /** Time open */
  openTime: Scalars['Float'];
};

export type PaginatedCompany = {
  __typename?: 'PaginatedCompany';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Company>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedCompanyUser = {
  __typename?: 'PaginatedCompanyUser';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<CompanyUser>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedDevice = {
  __typename?: 'PaginatedDevice';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Device>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedGroup = {
  __typename?: 'PaginatedGroup';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Group>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedMedia = {
  __typename?: 'PaginatedMedia';
  currentFolder?: Maybe<Media>;
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Media>>;
  s3FolderSize?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedPlaylist = {
  __typename?: 'PaginatedPlaylist';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Playlist>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<User>>;
  total: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type Playlist = {
  __typename?: 'Playlist';
  /** id */
  _id: Scalars['ID'];
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  createdAt: Scalars['Float'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  editingBy?: Maybe<User>;
  editingById?: Maybe<Scalars['ID']>;
  frames: Array<Frame>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  orientation?: Maybe<FrameDirection>;
  resolution?: Maybe<Resolutions>;
  screenType?: Maybe<PlaylistScreenType>;
  status?: Maybe<PlaylistStatus>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Float']>;
};

export enum PlaylistChangeGroupAction {
  Copy = 'COPY',
  Move = 'MOVE'
}

export enum PlaylistScreenType {
  FulScreen = 'FUL_SCREEN',
  SplitScreen = 'SPLIT_SCREEN'
}

export enum PlaylistStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  WaitToApprove = 'WAIT_TO_APPROVE'
}

export type PublicUpdateDeviceInfoInput = {
  localTime?: Maybe<Scalars['String']>;
  playlistIds: Array<Scalars['String']>;
  screenStatus: DeviceScreenStatus;
};

export type PublicUpdateDeviceScreenStatusInput = {
  status: DeviceScreenStatus;
};

export type PublicUpdateDeviceStatusInput = {
  status: DeviceStatus;
};

export type PublicUpdateRotateInput = {
  direction: DirectionRotateType;
};

export type Query = {
  __typename?: 'Query';
  calculateTotalStorage?: Maybe<Scalars['Float']>;
  companies?: Maybe<PaginatedCompany>;
  company?: Maybe<Company>;
  companyUsers?: Maybe<PaginatedCompanyUser>;
  device?: Maybe<Device>;
  devices?: Maybe<PaginatedDevice>;
  exportCompany?: Maybe<Scalars['String']>;
  getAllTotalInSystem?: Maybe<TotalNumberData>;
  getDeviceInfo?: Maybe<Device>;
  getDeviceStatus?: Maybe<DeviceStatus>;
  getDeviceVersion?: Maybe<Scalars['Float']>;
  getGroupPlaylists?: Maybe<PaginatedPlaylist>;
  group?: Maybe<Group>;
  groups?: Maybe<PaginatedGroup>;
  listUsersCanAddToCompany?: Maybe<PaginatedUser>;
  me: User;
  media?: Maybe<Media>;
  medias?: Maybe<PaginatedMedia>;
  pendingGroups?: Maybe<PaginatedGroup>;
  playlist?: Maybe<Playlist>;
  playlists?: Maybe<PaginatedPlaylist>;
  /** Device call lên server để tạo device theo device code nếu chưa có */
  publicGetDeviceByCode?: Maybe<Device>;
  settings?: Maybe<Array<Setting>>;
  settingsByKey: Array<Setting>;
  user?: Maybe<User>;
  /** filter users, "deleted" is filter deleted users */
  users?: Maybe<PaginatedUser>;
};


export type QueryCalculateTotalStorageArgs = {
  input: QueryCompanyInput;
};


export type QueryCompaniesArgs = {
  input: QueryCompaniesInput;
};


export type QueryCompanyArgs = {
  input: QueryCompanyInput;
};


export type QueryCompanyUsersArgs = {
  input: QueryCompanyUsersInput;
};


export type QueryDeviceArgs = {
  input: QueryDeviceInput;
};


export type QueryDevicesArgs = {
  input: QueryDevicesInput;
};


export type QueryGetGroupPlaylistsArgs = {
  input: QueryGroupPlaylistsInput;
};


export type QueryGroupArgs = {
  input: QueryGroupInput;
};


export type QueryGroupsArgs = {
  input: QueryGroupsInput;
};


export type QueryListUsersCanAddToCompanyArgs = {
  input: QueryUsersCanImportInput;
};


export type QueryMediaArgs = {
  input: QueryMediaInput;
};


export type QueryMediasArgs = {
  input: QueryMediasInput;
};


export type QueryPendingGroupsArgs = {
  input: QueryPendingGroupsInput;
};


export type QueryPlaylistArgs = {
  input: QueryPlaylistInput;
};


export type QueryPlaylistsArgs = {
  input: QueryPlaylistsInput;
};


export type QueryPublicGetDeviceByCodeArgs = {
  input: QueryDeviceByCodeInput;
};


export type QuerySettingsByKeyArgs = {
  input: Scalars['String'];
};


export type QueryUserArgs = {
  input: QueryUserInput;
};


export type QueryUsersArgs = {
  input: QueryUsersInput;
};

export type QueryCompaniesInput = {
  afterId?: Maybe<Scalars['ID']>;
  capacity?: Maybe<Capacity>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  userLimit?: Maybe<UserLimit>;
};

export type QueryCompanyInput = {
  _id: Scalars['ID'];
};

export type QueryCompanyUsersInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};

export type QueryDeviceByCodeInput = {
  deviceCode?: Maybe<Scalars['String']>;
  deviceCodeHash?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
};

export type QueryDeviceInput = {
  _id: Scalars['ID'];
};

export type QueryDevicesInput = {
  afterId?: Maybe<Scalars['ID']>;
  availableCompany?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  os?: Maybe<OsType>;
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  screen?: Maybe<ScreenType>;
  status?: Maybe<DeviceStatus>;
};

export type QueryGroupInput = {
  _id: Scalars['ID'];
};

export type QueryGroupPlaylistsInput = {
  afterId?: Maybe<Scalars['ID']>;
  groupId: Scalars['ID'];
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryGroupsInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  status?: Maybe<Array<GroupStatus>>;
};

export type QueryMediaInput = {
  _id: Scalars['ID'];
};

export type QueryMediasInput = {
  afterId?: Maybe<Scalars['ID']>;
  filterType?: Maybe<MediaFilterType>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  order?: Maybe<MediaOrderBy>;
  page?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  status?: Maybe<MediaStatus>;
  type?: Maybe<FileType>;
};

export type QueryPendingGroupsInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryPlaylistInput = {
  _id: Scalars['ID'];
};

export type QueryPlaylistsInput = {
  afterId?: Maybe<Scalars['ID']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryUserInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type QueryUsersCanImportInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  isLoadMore?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
};

export type QueryUsersInput = {
  afterId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['String']>;
  /** Bao gồm các user đã bị xóa */
  deleted?: Maybe<Scalars['Boolean']>;
  isLoadMore?: Maybe<Scalars['Boolean']>;
  /** Khi tìm kiếm user để gán quyền vào company, set isSearchAll = true để lấy tất cả user */
  isSearchAll?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
  page?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};

export enum Roles {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  SuperAdmin = 'SUPER_ADMIN'
}

export type RefreshTokenInput = {
  /** old access token, accept expired token */
  accessToken: Scalars['String'];
  /** Input refresh token */
  refreshToken: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordUserInput = {
  email: Scalars['String'];
};

export enum Resolutions {
  FullHd = 'FULL_HD',
  Hd = 'HD'
}

export type ScanWifiDeviceInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['String']>;
};

export type ScheduleLayoutSettings = {
  __typename?: 'ScheduleLayoutSettings';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColorSecond?: Maybe<Scalars['String']>;
  displayMode?: Maybe<DisplayMode>;
  orientation?: Maybe<FrameDirection>;
  resolution?: Maybe<Resolutions>;
  screenType?: Maybe<PlaylistScreenType>;
};

export type ScheduleLayoutSettingsInput = {
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColorSecond?: Maybe<Scalars['String']>;
  displayMode: DisplayMode;
  orientation: FrameDirection;
  resolution: Resolutions;
  screenType: PlaylistScreenType;
};

export enum ScheduleType {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  SpecialDay = 'SPECIAL_DAY',
  Weekly = 'WEEKLY'
}

export enum ScreenType {
  Led = 'LED',
  Oled = 'OLED',
  Qled = 'QLED'
}

export type SendGroupMessageInput = {
  direction: GroupMessageDirection;
  groupId: Scalars['ID'];
  loop: Scalars['Int'];
  message: Scalars['String'];
  speed: GroupMessageSpeed;
};

export type SendWifiInput = {
  listWifi: Array<WifiTypeInput>;
};

export type SetPlaylistToGroup = {
  _id: Scalars['ID'];
  groupId: Scalars['ID'];
};

export type SetPlaylistToOtherGroup = {
  _id: Scalars['ID'];
  action: PlaylistChangeGroupAction;
  groupId: Scalars['ID'];
};

export type Setting = {
  __typename?: 'Setting';
  _id: Scalars['ID'];
  key: SettingType;
  value: Array<SettingData>;
};

export type SettingData = {
  __typename?: 'SettingData';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SettingDeviceInput = {
  _id: Scalars['ID'];
  subscriptionDate?: Maybe<SubscriptionDateInput>;
  wifi?: Maybe<WifiTypeInput>;
};

export enum SettingType {
  Device = 'DEVICE',
  Upload = 'UPLOAD'
}

export enum Sort {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SpecialDay = {
  __typename?: 'SpecialDay';
  /** id */
  _id: Scalars['ID'];
  closedTime: Scalars['Float'];
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Float']>;
  date: Scalars['Float'];
  openTime: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
};

export type SpecialDayScheduleData = {
  __typename?: 'SpecialDayScheduleData';
  /** fromDate */
  date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  timeInDay?: Maybe<TimeInDay>;
  toDate?: Maybe<Scalars['String']>;
};

export type SpecialDayScheduleInputData = {
  /** fromDate */
  date: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  timeInDay?: Maybe<TimeInDayInput>;
  toDate?: Maybe<Scalars['String']>;
};

export type StatusConnectWifiInput = {
  password?: Maybe<Scalars['String']>;
  ssid: Scalars['String'];
  status: Scalars['Boolean'];
};

export enum SubscriberType {
  Free = 'FREE',
  NineMonth = 'NINE_MONTH',
  SixMonth = 'SIX_MONTH',
  ThirtySixMonth = 'THIRTY_SIX_MONTH',
  ThreeMonth = 'THREE_MONTH',
  TwelveMonth = 'TWELVE_MONTH',
  TwentyFourMonth = 'TWENTY_FOUR_MONTH'
}

export type Subscription = {
  __typename?: 'Subscription';
  deviceSubs?: Maybe<DevicePubSub>;
  mediaProcess: Media;
};

export type SubscriptionDate = {
  __typename?: 'SubscriptionDate';
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type SubscriptionDateInput = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type TimeInDay = {
  __typename?: 'TimeInDay';
  /** Time end */
  endOfDay: Scalars['String'];
  /** Time start */
  startOfDay: Scalars['String'];
};

export type TimeInDayInput = {
  /** Time end */
  endOfDay: Scalars['String'];
  /** Time start */
  startOfDay: Scalars['String'];
};

export type TotalNumberData = {
  __typename?: 'TotalNumberData';
  availableDevices?: Maybe<Scalars['Float']>;
  companies?: Maybe<Scalars['Float']>;
  devices: Scalars['Float'];
  groups?: Maybe<Scalars['Float']>;
  pendingGroups?: Maybe<Scalars['Float']>;
  users: Scalars['Float'];
};

export enum User_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UpdateCompanyInput = {
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  address_latitude?: Maybe<Scalars['Float']>;
  address_longitude?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Capacity>;
  currentStorageSize?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Float']>;
  limitUpload: Scalars['Float'];
  name: Scalars['String'];
  openTimes?: Maybe<Array<OpenTimeInput>>;
  phone: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  userLimit?: Maybe<UserLimit>;
};

export type UpdateDeviceCurrentPlaylistsInput = {
  localTime: Scalars['String'];
  playlistIds: Array<Scalars['String']>;
};

export type UpdateDeviceInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  deviceModel?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  os?: Maybe<OsType>;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  screen?: Maybe<ScreenType>;
  yearOfManufacture?: Maybe<Scalars['Float']>;
};

export type UpdateDeviceSchedulesInput = {
  _id: Scalars['ID'];
  groupTimeZone: Scalars['String'];
  layoutSettings: ScheduleLayoutSettingsInput;
  schedules?: Maybe<Array<DevicePlaylistScheduleInput>>;
  schedulesSecond?: Maybe<Array<DevicePlaylistScheduleInput>>;
};

export type UpdateDeviceStatusInput = {
  _id: Scalars['ID'];
  status: DeviceStatus;
};

export type UpdateGroupInput = {
  _id: Scalars['ID'];
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type UpdateGroupStatusInput = {
  _id: Scalars['ID'];
  status: GroupStatus;
};

export type UpdateMediaInput = {
  _id: Scalars['ID'];
  filename: Scalars['String'];
};

export type UpdatePlaylistInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  frames?: Maybe<Array<FrameInputType>>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateRotateInput = {
  _id: Scalars['ID'];
  direction: DirectionRotateType;
};

export type UpdateTimeZoneGroupInput = {
  _id: Scalars['ID'];
  timezone: Scalars['String'];
};

export type UpdateUserInput = {
  _id: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};


export type UploadSettingDataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** id */
  _id: Scalars['ID'];
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Float']>;
  deletedBy?: Maybe<Scalars['ID']>;
  deletedByUser?: Maybe<User>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** full name */
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Roles;
  status: User_Status;
  tokenExpired?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy: User;
  updatedById: Scalars['ID'];
  username: Scalars['String'];
};

export enum UserLimit {
  Fifty = 'FIFTY',
  Hundred = 'HUNDRED',
  Thousand = 'THOUSAND',
  Twenty = 'TWENTY'
}

export type VerifyTokenAutoLoginInput = {
  token: Scalars['String'];
};

export enum VideoPreset {
  Video360P = 'Video360P',
  Video480P = 'Video480P',
  Video720P = 'Video720P',
  Video1080P = 'Video1080P'
}

export type VideoProfile = {
  __typename?: 'VideoProfile';
  height?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  preset?: Maybe<VideoPreset>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type VideoProfileInput = {
  path?: Maybe<Scalars['String']>;
  preset?: Maybe<VideoPreset>;
  url?: Maybe<Scalars['String']>;
};

export type WeeklyScheduleData = {
  __typename?: 'WeeklyScheduleData';
  dateRange?: Maybe<DateRange>;
  daysOfWeek: Array<Scalars['Int']>;
  timeInDay?: Maybe<TimeInDay>;
};

export type WeeklyScheduleInputData = {
  dateRange?: Maybe<DateRangeInput>;
  daysOfWeek: Array<Scalars['Int']>;
  timeInDay?: Maybe<TimeInDayInput>;
};

export type WifiType = {
  __typename?: 'WifiType';
  encryptMode: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  signalLevel?: Maybe<Scalars['Int']>;
  ssid: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type WifiTypeInput = {
  encryptMode: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  signalLevel?: Maybe<Scalars['Int']>;
  ssid: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type AddNewDeviceVariables = Exact<{
  input: CreateDeviceInput;
}>;


export type AddNewDevice = (
  { __typename?: 'Mutation' }
  & { createDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'name' | 'deviceModel' | 'os' | 'screen' | 'yearOfManufacture' | 'companyId'>
  )> }
);

export type UpdateDeviceVariables = Exact<{
  input: UpdateDeviceInput;
}>;


export type UpdateDevice = (
  { __typename?: 'Mutation' }
  & { updateDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'companyId' | 'deviceModel' | 'name' | 'os' | 'screen' | 'uniqueCode' | 'yearOfManufacture'>
  )> }
);

export type DeleteDeviceVariables = Exact<{
  input: DeleteDeviceInput;
}>;


export type DeleteDevice = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDevice'>
);

export type LoginVariables = Exact<{
  input: LoginInput;
}>;


export type Login = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'expiresAt' | 'role' | 'companyId' | 'token' | 'refreshTokenExpiresAt' | 'refreshToken'>
    & { payload: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'username' | 'role'>
    ) }
  ) }
);

export type SendScreenshotVariables = Exact<{
  input: CaptureScreenInput;
}>;


export type SendScreenshot = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendScreenshot'>
);

export type PublicUpdateDeviceScreenStatusVariables = Exact<{
  input: PublicUpdateDeviceScreenStatusInput;
}>;


export type PublicUpdateDeviceScreenStatus = (
  { __typename?: 'Mutation' }
  & { publicUpdateDeviceScreenStatus?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id'>
  )> }
);

export type SendWifiDeviceVariables = Exact<{
  input: SendWifiInput;
}>;


export type SendWifiDevice = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendWifiDevice'>
);

export type SendWifiConnectionStatusVariables = Exact<{
  input: StatusConnectWifiInput;
}>;


export type SendWifiConnectionStatus = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'statusConnectWifi'>
);

export type UpdateDeviceCurrentPlaylistsVariables = Exact<{
  input: UpdateDeviceCurrentPlaylistsInput;
}>;


export type UpdateDeviceCurrentPlaylists = (
  { __typename?: 'Mutation' }
  & { updateDeviceCurrentPlaylists?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'name'>
  )> }
);

export type PublicUpdateRotateScreenVariables = Exact<{
  input: PublicUpdateRotateInput;
}>;


export type PublicUpdateRotateScreen = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publicUpdateRotateScreen'>
);

export type PublicUpdateDeviceInfoVariables = Exact<{
  input: PublicUpdateDeviceInfoInput;
}>;


export type PublicUpdateDeviceInfo = (
  { __typename?: 'Mutation' }
  & { publicUpdateDeviceInfo?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id'>
  )> }
);

export type GetPublicDeviceByCodeVariables = Exact<{
  input: QueryDeviceByCodeInput;
}>;


export type GetPublicDeviceByCode = (
  { __typename?: 'Query' }
  & { publicGetDeviceByCode?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'momentDateFormat' | 'groupId' | 'status' | 'screenStatus' | 'adminScreenStatus' | 'companyId' | 'name' | 'deviceCode' | 'token' | 'direction'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'scheduleVersion' | 'companyId' | 'timezone' | 'status'>
      & { layoutSettings?: Maybe<(
        { __typename?: 'ScheduleLayoutSettings' }
        & Pick<ScheduleLayoutSettings, 'backgroundColor' | 'backgroundColorSecond' | 'resolution' | 'orientation' | 'screenType' | 'displayMode'>
      )>, schedules?: Maybe<Array<(
        { __typename?: 'DevicePlaylistSchedule' }
        & Pick<DevicePlaylistSchedule, 'playlistId' | 'type'>
        & { dailyData?: Maybe<(
          { __typename?: 'DailyScheduleData' }
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, weeklyData?: Maybe<(
          { __typename?: 'WeeklyScheduleData' }
          & Pick<WeeklyScheduleData, 'daysOfWeek'>
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, monthlyData?: Maybe<(
          { __typename?: 'MonthlyScheduleData' }
          & { months?: Maybe<Array<(
            { __typename?: 'MonthInYear' }
            & Pick<MonthInYear, 'days' | 'month'>
          )>>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, specialDayData?: Maybe<Array<(
          { __typename?: 'SpecialDayScheduleData' }
          & Pick<SpecialDayScheduleData, 'date' | 'toDate'>
          & { timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>>, playlist?: Maybe<(
          { __typename?: 'Playlist' }
          & Pick<Playlist, '_id' | 'companyId' | 'name' | 'version'>
          & { frames: Array<(
            { __typename?: 'Frame' }
            & Pick<Frame, 'webUrl' | 'duration' | 'order' | 'type'>
            & { documentImages?: Maybe<Array<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
            )>>, videoProfiles?: Maybe<Array<(
              { __typename?: 'VideoProfile' }
              & Pick<VideoProfile, 'path' | 'preset' | 'url'>
            )>>, image?: Maybe<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'mimetype' | 'width' | 'height' | 'url'>
            )> }
          )> }
        )> }
      )>>, schedulesSecond?: Maybe<Array<(
        { __typename?: 'DevicePlaylistSchedule' }
        & Pick<DevicePlaylistSchedule, 'playlistId' | 'type'>
        & { dailyData?: Maybe<(
          { __typename?: 'DailyScheduleData' }
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, weeklyData?: Maybe<(
          { __typename?: 'WeeklyScheduleData' }
          & Pick<WeeklyScheduleData, 'daysOfWeek'>
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, monthlyData?: Maybe<(
          { __typename?: 'MonthlyScheduleData' }
          & { months?: Maybe<Array<(
            { __typename?: 'MonthInYear' }
            & Pick<MonthInYear, 'days' | 'month'>
          )>>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, specialDayData?: Maybe<Array<(
          { __typename?: 'SpecialDayScheduleData' }
          & Pick<SpecialDayScheduleData, 'date' | 'toDate'>
          & { timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>>, playlist?: Maybe<(
          { __typename?: 'Playlist' }
          & Pick<Playlist, '_id' | 'companyId' | 'name' | 'version'>
          & { frames: Array<(
            { __typename?: 'Frame' }
            & Pick<Frame, 'webUrl' | 'duration' | 'order' | 'type'>
            & { documentImages?: Maybe<Array<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
            )>>, videoProfiles?: Maybe<Array<(
              { __typename?: 'VideoProfile' }
              & Pick<VideoProfile, 'path' | 'preset' | 'url'>
            )>>, image?: Maybe<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'mimetype' | 'width' | 'height' | 'url'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type SettingsVariables = Exact<{ [key: string]: never; }>;


export type Settings = (
  { __typename?: 'Query' }
  & { settings?: Maybe<Array<(
    { __typename?: 'Setting' }
    & Pick<Setting, '_id' | 'key'>
    & { value: Array<(
      { __typename?: 'SettingData' }
      & Pick<SettingData, 'key' | 'value'>
    )> }
  )>> }
);

export type GetDeviceInfoVariables = Exact<{ [key: string]: never; }>;


export type GetDeviceInfo = (
  { __typename?: 'Query' }
  & { getDeviceInfo?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, '_id' | 'momentDateFormat' | 'version' | 'groupId' | 'status' | 'screenStatus' | 'adminScreenStatus' | 'companyId' | 'name' | 'deviceCode' | 'token' | 'direction'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'scheduleVersion' | 'companyId' | 'status' | 'timezone'>
      & { layoutSettings?: Maybe<(
        { __typename?: 'ScheduleLayoutSettings' }
        & Pick<ScheduleLayoutSettings, 'backgroundColor' | 'backgroundColorSecond' | 'resolution' | 'orientation' | 'screenType' | 'displayMode'>
      )>, schedules?: Maybe<Array<(
        { __typename?: 'DevicePlaylistSchedule' }
        & Pick<DevicePlaylistSchedule, 'playlistId' | 'type'>
        & { dailyData?: Maybe<(
          { __typename?: 'DailyScheduleData' }
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, weeklyData?: Maybe<(
          { __typename?: 'WeeklyScheduleData' }
          & Pick<WeeklyScheduleData, 'daysOfWeek'>
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, monthlyData?: Maybe<(
          { __typename?: 'MonthlyScheduleData' }
          & { months?: Maybe<Array<(
            { __typename?: 'MonthInYear' }
            & Pick<MonthInYear, 'days' | 'month'>
          )>>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, specialDayData?: Maybe<Array<(
          { __typename?: 'SpecialDayScheduleData' }
          & Pick<SpecialDayScheduleData, 'date' | 'toDate'>
          & { timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>>, playlist?: Maybe<(
          { __typename?: 'Playlist' }
          & Pick<Playlist, '_id' | 'companyId' | 'name' | 'version'>
          & { frames: Array<(
            { __typename?: 'Frame' }
            & Pick<Frame, 'webUrl' | 'duration' | 'order' | 'type'>
            & { availability?: Maybe<(
              { __typename?: 'Availability' }
              & Pick<Availability, 'enabled' | 'fromDate' | 'toDate'>
            )>, documentImages?: Maybe<Array<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
            )>>, videoProfiles?: Maybe<Array<(
              { __typename?: 'VideoProfile' }
              & Pick<VideoProfile, 'path' | 'preset' | 'url'>
            )>>, image?: Maybe<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'mimetype' | 'width' | 'height' | 'url'>
            )> }
          )> }
        )> }
      )>>, schedulesSecond?: Maybe<Array<(
        { __typename?: 'DevicePlaylistSchedule' }
        & Pick<DevicePlaylistSchedule, 'playlistId' | 'type'>
        & { dailyData?: Maybe<(
          { __typename?: 'DailyScheduleData' }
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, weeklyData?: Maybe<(
          { __typename?: 'WeeklyScheduleData' }
          & Pick<WeeklyScheduleData, 'daysOfWeek'>
          & { dateRange?: Maybe<(
            { __typename?: 'DateRange' }
            & Pick<DateRange, 'dateFrom' | 'dateTo'>
          )>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, monthlyData?: Maybe<(
          { __typename?: 'MonthlyScheduleData' }
          & { months?: Maybe<Array<(
            { __typename?: 'MonthInYear' }
            & Pick<MonthInYear, 'days' | 'month'>
          )>>, timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>, specialDayData?: Maybe<Array<(
          { __typename?: 'SpecialDayScheduleData' }
          & Pick<SpecialDayScheduleData, 'date' | 'toDate'>
          & { timeInDay?: Maybe<(
            { __typename?: 'TimeInDay' }
            & Pick<TimeInDay, 'endOfDay' | 'startOfDay'>
          )> }
        )>>, playlist?: Maybe<(
          { __typename?: 'Playlist' }
          & Pick<Playlist, '_id' | 'companyId' | 'name' | 'version'>
          & { frames: Array<(
            { __typename?: 'Frame' }
            & Pick<Frame, 'webUrl' | 'duration' | 'order' | 'type'>
            & { availability?: Maybe<(
              { __typename?: 'Availability' }
              & Pick<Availability, 'enabled' | 'fromDate' | 'toDate'>
            )>, documentImages?: Maybe<Array<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'height' | 'mimetype' | 'path' | 'url' | 'width'>
            )>>, videoProfiles?: Maybe<Array<(
              { __typename?: 'VideoProfile' }
              & Pick<VideoProfile, 'path' | 'preset' | 'url'>
            )>>, image?: Maybe<(
              { __typename?: 'MediaImage' }
              & Pick<MediaImage, 'mimetype' | 'width' | 'height' | 'url'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetDeviceVersionVariables = Exact<{ [key: string]: never; }>;


export type GetDeviceVersion = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDeviceVersion'>
);


export const AddNewDeviceDocument = gql`
    mutation addNewDevice($input: CreateDeviceInput!) {
  createDevice(input: $input) {
    name
    deviceModel
    os
    screen
    yearOfManufacture
    companyId
  }
}
    `;
export const UpdateDeviceDocument = gql`
    mutation updateDevice($input: UpdateDeviceInput!) {
  updateDevice(input: $input) {
    _id
    companyId
    deviceModel
    name
    os
    screen
    uniqueCode
    yearOfManufacture
  }
}
    `;
export const DeleteDeviceDocument = gql`
    mutation deleteDevice($input: DeleteDeviceInput!) {
  deleteDevice(input: $input)
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    expiresAt
    payload {
      _id
      name
      username
      role
    }
    role
    companyId
    token
    refreshTokenExpiresAt
    refreshToken
  }
}
    `;
export const SendScreenshotDocument = gql`
    mutation SendScreenshot($input: CaptureScreenInput!) {
  sendScreenshot(input: $input)
}
    `;
export const PublicUpdateDeviceScreenStatusDocument = gql`
    mutation publicUpdateDeviceScreenStatus($input: PublicUpdateDeviceScreenStatusInput!) {
  publicUpdateDeviceScreenStatus(input: $input) {
    _id
  }
}
    `;
export const SendWifiDeviceDocument = gql`
    mutation SendWifiDevice($input: SendWifiInput!) {
  sendWifiDevice(input: $input)
}
    `;
export const SendWifiConnectionStatusDocument = gql`
    mutation sendWifiConnectionStatus($input: StatusConnectWifiInput!) {
  statusConnectWifi(input: $input)
}
    `;
export const UpdateDeviceCurrentPlaylistsDocument = gql`
    mutation UpdateDeviceCurrentPlaylists($input: UpdateDeviceCurrentPlaylistsInput!) {
  updateDeviceCurrentPlaylists(input: $input) {
    _id
    name
  }
}
    `;
export const PublicUpdateRotateScreenDocument = gql`
    mutation publicUpdateRotateScreen($input: PublicUpdateRotateInput!) {
  publicUpdateRotateScreen(input: $input)
}
    `;
export const PublicUpdateDeviceInfoDocument = gql`
    mutation publicUpdateDeviceInfo($input: PublicUpdateDeviceInfoInput!) {
  publicUpdateDeviceInfo(input: $input) {
    _id
  }
}
    `;
export const GetPublicDeviceByCodeDocument = gql`
    query getPublicDeviceByCode($input: QueryDeviceByCodeInput!) {
  publicGetDeviceByCode(input: $input) {
    _id
    momentDateFormat
    groupId
    status
    screenStatus
    adminScreenStatus
    companyId
    name
    deviceCode
    token
    direction
    group {
      _id
      scheduleVersion
      companyId
      timezone
      status
      layoutSettings {
        backgroundColor
        backgroundColorSecond
        resolution
        orientation
        screenType
        displayMode
      }
      schedules {
        dailyData {
          dateRange {
            dateFrom
            dateTo
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        weeklyData {
          dateRange {
            dateFrom
            dateTo
          }
          daysOfWeek
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        monthlyData {
          months {
            days
            month
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        specialDayData {
          timeInDay {
            endOfDay
            startOfDay
          }
          date
          toDate
        }
        playlist {
          _id
          companyId
          name
          version
          frames {
            documentImages {
              height
              mimetype
              path
              url
              width
            }
            videoProfiles {
              path
              preset
              url
            }
            image {
              mimetype
              width
              height
              url
            }
            webUrl
            duration
            order
            type
          }
        }
        playlistId
        type
      }
      schedulesSecond {
        dailyData {
          dateRange {
            dateFrom
            dateTo
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        weeklyData {
          dateRange {
            dateFrom
            dateTo
          }
          daysOfWeek
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        monthlyData {
          months {
            days
            month
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        specialDayData {
          timeInDay {
            endOfDay
            startOfDay
          }
          date
          toDate
        }
        playlist {
          _id
          companyId
          name
          version
          frames {
            documentImages {
              height
              mimetype
              path
              url
              width
            }
            videoProfiles {
              path
              preset
              url
            }
            image {
              mimetype
              width
              height
              url
            }
            webUrl
            duration
            order
            type
          }
        }
        playlistId
        type
      }
    }
  }
}
    `;
export const SettingsDocument = gql`
    query Settings {
  settings {
    _id
    key
    value {
      key
      value
    }
  }
}
    `;
export const GetDeviceInfoDocument = gql`
    query getDeviceInfo {
  getDeviceInfo {
    _id
    momentDateFormat
    version
    groupId
    status
    screenStatus
    adminScreenStatus
    companyId
    name
    deviceCode
    token
    direction
    group {
      _id
      scheduleVersion
      companyId
      status
      timezone
      layoutSettings {
        backgroundColor
        backgroundColorSecond
        resolution
        orientation
        screenType
        displayMode
      }
      schedules {
        dailyData {
          dateRange {
            dateFrom
            dateTo
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        weeklyData {
          dateRange {
            dateFrom
            dateTo
          }
          daysOfWeek
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        monthlyData {
          months {
            days
            month
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        specialDayData {
          timeInDay {
            endOfDay
            startOfDay
          }
          date
          toDate
        }
        playlist {
          _id
          companyId
          name
          version
          frames {
            availability {
              enabled
              fromDate
              toDate
            }
            documentImages {
              height
              mimetype
              path
              url
              width
            }
            videoProfiles {
              path
              preset
              url
            }
            image {
              mimetype
              width
              height
              url
            }
            webUrl
            duration
            order
            type
          }
        }
        playlistId
        type
      }
      schedulesSecond {
        dailyData {
          dateRange {
            dateFrom
            dateTo
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        weeklyData {
          dateRange {
            dateFrom
            dateTo
          }
          daysOfWeek
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        monthlyData {
          months {
            days
            month
          }
          timeInDay {
            endOfDay
            startOfDay
          }
        }
        specialDayData {
          timeInDay {
            endOfDay
            startOfDay
          }
          date
          toDate
        }
        playlist {
          _id
          companyId
          name
          version
          frames {
            availability {
              enabled
              fromDate
              toDate
            }
            documentImages {
              height
              mimetype
              path
              url
              width
            }
            videoProfiles {
              path
              preset
              url
            }
            image {
              mimetype
              width
              height
              url
            }
            webUrl
            duration
            order
            type
          }
        }
        playlistId
        type
      }
    }
  }
}
    `;
export const GetDeviceVersionDocument = gql`
    query getDeviceVersion {
  getDeviceVersion
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addNewDevice(variables: AddNewDeviceVariables): Promise<AddNewDevice> {
      return withWrapper(() => client.request<AddNewDevice>(print(AddNewDeviceDocument), variables));
    },
    updateDevice(variables: UpdateDeviceVariables): Promise<UpdateDevice> {
      return withWrapper(() => client.request<UpdateDevice>(print(UpdateDeviceDocument), variables));
    },
    deleteDevice(variables: DeleteDeviceVariables): Promise<DeleteDevice> {
      return withWrapper(() => client.request<DeleteDevice>(print(DeleteDeviceDocument), variables));
    },
    login(variables: LoginVariables): Promise<Login> {
      return withWrapper(() => client.request<Login>(print(LoginDocument), variables));
    },
    SendScreenshot(variables: SendScreenshotVariables): Promise<SendScreenshot> {
      return withWrapper(() => client.request<SendScreenshot>(print(SendScreenshotDocument), variables));
    },
    publicUpdateDeviceScreenStatus(variables: PublicUpdateDeviceScreenStatusVariables): Promise<PublicUpdateDeviceScreenStatus> {
      return withWrapper(() => client.request<PublicUpdateDeviceScreenStatus>(print(PublicUpdateDeviceScreenStatusDocument), variables));
    },
    SendWifiDevice(variables: SendWifiDeviceVariables): Promise<SendWifiDevice> {
      return withWrapper(() => client.request<SendWifiDevice>(print(SendWifiDeviceDocument), variables));
    },
    sendWifiConnectionStatus(variables: SendWifiConnectionStatusVariables): Promise<SendWifiConnectionStatus> {
      return withWrapper(() => client.request<SendWifiConnectionStatus>(print(SendWifiConnectionStatusDocument), variables));
    },
    UpdateDeviceCurrentPlaylists(variables: UpdateDeviceCurrentPlaylistsVariables): Promise<UpdateDeviceCurrentPlaylists> {
      return withWrapper(() => client.request<UpdateDeviceCurrentPlaylists>(print(UpdateDeviceCurrentPlaylistsDocument), variables));
    },
    publicUpdateRotateScreen(variables: PublicUpdateRotateScreenVariables): Promise<PublicUpdateRotateScreen> {
      return withWrapper(() => client.request<PublicUpdateRotateScreen>(print(PublicUpdateRotateScreenDocument), variables));
    },
    publicUpdateDeviceInfo(variables: PublicUpdateDeviceInfoVariables): Promise<PublicUpdateDeviceInfo> {
      return withWrapper(() => client.request<PublicUpdateDeviceInfo>(print(PublicUpdateDeviceInfoDocument), variables));
    },
    getPublicDeviceByCode(variables: GetPublicDeviceByCodeVariables): Promise<GetPublicDeviceByCode> {
      return withWrapper(() => client.request<GetPublicDeviceByCode>(print(GetPublicDeviceByCodeDocument), variables));
    },
    Settings(variables?: SettingsVariables): Promise<Settings> {
      return withWrapper(() => client.request<Settings>(print(SettingsDocument), variables));
    },
    getDeviceInfo(variables?: GetDeviceInfoVariables): Promise<GetDeviceInfo> {
      return withWrapper(() => client.request<GetDeviceInfo>(print(GetDeviceInfoDocument), variables));
    },
    getDeviceVersion(variables?: GetDeviceVersionVariables): Promise<GetDeviceVersion> {
      return withWrapper(() => client.request<GetDeviceVersion>(print(GetDeviceVersionDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;