import env from '@/env';
import { useMutation } from 'react-query';
import { CacheRoute } from './useOfflinePlaylist';

export enum CecStatus {
  'on' = 'on',
  'standby' = 'standby',
  'unknown' = 'unknown',
}

export default function useChangeCecPower() {
  return useMutation(async (status: CecStatus.on | CecStatus.standby) => {
    console.log('on/off screen: ', status);
    let postRoute = `${env.piApiEndPoint}/${CacheRoute.CEC_STATUS}`;
    const rawResponse = await fetch(postRoute, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });
    const content = await rawResponse.json();
    return content;
  });
}
