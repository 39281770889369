import 'antd/dist/antd.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { renderRoutes } from 'react-router-config';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import queryClient from './query-client';
import routes from './router';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './assets/styles/app.scss';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import PingPong from '@commons/Components/Layout/PingPong';
// import './wdyr';

console.warn = () => {};

ReactDOM.render(
  <Suspense fallback="loading">
    <RecoilRoot>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <PingPong />
          {renderRoutes(routes)}
        </QueryClientProvider>
      </HashRouter>
    </RecoilRoot>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.unregister();
