import useGetPlaylistCache from '@hooks/useGetPlaylistCache';
import useOfflinePlaylist from '@hooks/useOfflinePlaylist';
import useUpdatePlaylistsCache from '@hooks/useUpdatePlaylistsCache';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { GroupStatus, PlaylistScreenType } from '../../../graphql/generated/graphql';
import { calculateSize } from '../../../helpers/resolutionSize';
import { globalState } from '../../state';
import { calculateSchedulesData, getCurrentSchedules } from '../helpers/check-schedule';
import useRecordListener from '../hooks/useRecordListener';
import { SizeInterface } from './FrameComponent';
import LoadingCacheProgress from './LoadingCacheProgress';
import NoConnection from './NoConnection';
import NoDevice from './NoDevice';
import PlaylistLayout from './PlaylistLayout';
import './style.scss';

const Index = () => {
  const IntervalCheckSchedulesPlaying = useRef<any>();
  const [appState, setAppState] = useRecoilState(globalState);

  const { onDetectUrlFromPlaylist } = useOfflinePlaylist();
  const { mutate: onUpdatePlaylistsCache } = useUpdatePlaylistsCache();

  // Lấy dữ liệu playlist từ cache
  const { data: playlistFromCacheData } = useGetPlaylistCache(
    appState.currentSchedules?.length ? appState.currentSchedules[0]?.playlist?._id : '',
    appState.deviceVersion,
  );
  const { data: playlistFromCacheDataSecond } = useGetPlaylistCache(
    (appState.currentSchedules?.length && appState.currentSchedules[1]?.playlist?._id) || '',
    appState.deviceVersion,
  );

  const [size, setSize] = useState<SizeInterface>({
    height: 0,
    width: 0,
    ratio: 1,
    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth,
  });

  useRecordListener(appState.deviceCode);

  // Lấy thông tin playlist hiện tại, cập nhật lại device screenStatus khi currentSchedules thay đổi
  async function getCurrentPlaylistsData(timezone: string) {
    console.log('getCurrentPlaylistsData');
    if (appState.currentDeviceObj) {
      const currentDate = moment().tz(timezone);
      const schedules = await getCurrentSchedules(currentDate);
      // Trong thời gian gần, không có playlist nào thì chuyển currentSchedules sang mảng rỗng
      if (schedules.offScreen) {
        setAppState((state) => ({ ...state, currentSchedules: [], isSchedulesCalculated: true }));
      } else {
        setAppState((state) => ({
          ...state,
          currentSchedules: [schedules.firstScreenSchedule, schedules.secondScreenSchedule],
          isSchedulesCalculated: true,
        }));
      }
    }
  }

  // Load lần đầu khi mở ứng dụng
  useEffect(() => {
    if (appState.currentDeviceObj) {
      //nếu device đang ở trong group có lịch chạy, tính toán lại lịch chạy
      const calculate = async () => {
        if (
          appState.schedulesInDay === undefined &&
          appState?.currentDeviceObj?.group &&
          appState?.currentDeviceObj.group.status === GroupStatus.Approved &&
          appState?.currentDeviceObj.group.scheduleVersion &&
          (appState?.currentDeviceObj.group.schedules || appState.currentDeviceObj.group.schedulesSecond) &&
          appState.layoutSettings?.screenType
        ) {
          const data = await calculateSchedulesData(
            appState.layoutSettings?.screenType,
            appState.currentDeviceObj.group.schedules,
            appState.currentDeviceObj.group.schedulesSecond,
            appState.currentDeviceObj.group.scheduleVersion,
            appState.currentDeviceObj.momentDateFormat || 'DD.MM.YYYY',
          );
          return data;
        }
      };
      if (
        appState.schedulesInDay === undefined &&
        appState.currentDeviceObj.group &&
        appState.currentDeviceObj.group.status === GroupStatus.Approved &&
        appState.currentDeviceObj.group.scheduleVersion &&
        (appState.currentDeviceObj.group.schedules || appState.currentDeviceObj.group.schedulesSecond) &&
        appState.layoutSettings?.screenType
      ) {
        calculate().then((schedules) => {
          setAppState((state) => ({ ...state, schedulesInDay: schedules }));
        });
      } else {
        const data = onDetectUrlFromPlaylist(appState.currentDeviceObj);
        onUpdatePlaylistsCache(data);
      }

      // Định kỳ kiểm tra lịch chạy
      IntervalCheckSchedulesPlaying.current = setInterval(async () => {
        if (appState.currentDeviceObj && appState.currentDeviceObj?.group?.timezone) {
          if (appState.schedulesInDay?.day !== moment().tz(appState.currentDeviceObj?.group?.timezone).dayOfYear()) {
            if (
              appState.currentDeviceObj &&
              appState.currentDeviceObj?.group &&
              appState.currentDeviceObj.group.status === GroupStatus.Approved &&
              appState.currentDeviceObj.group.scheduleVersion &&
              appState.layoutSettings?.screenType
            ) {
              console.log('calculate schedule in new day');
              const schedules = await calculateSchedulesData(
                appState.layoutSettings?.screenType,
                appState.currentDeviceObj.group.schedules,
                appState.currentDeviceObj.group.schedulesSecond,
                appState.currentDeviceObj.group.scheduleVersion,
                appState.currentDeviceObj.momentDateFormat || 'DD.MM.YYYY',
              );
              setAppState((state) => ({ ...state, schedulesInDay: schedules }));
            }
          } else {
            getCurrentPlaylistsData(appState.currentDeviceObj.group.timezone);
          }
        }
      }, 10000);
    }
    return () => {
      IntervalCheckSchedulesPlaying.current && clearInterval(IntervalCheckSchedulesPlaying.current);
    };
    // eslint-disable-next-line
  }, [appState.schedulesInDay?.day, appState.schedulesInDay?.scheduleVersion]);

  //=====================check screen size================================//
  useEffect(() => {
    if (
      appState.currentDeviceObj?.group &&
      appState.layoutSettings?.resolution &&
      appState.layoutSettings?.screenType &&
      appState.layoutSettings?.orientation
    ) {
      const clientW = window.innerWidth;
      const clientH = window.innerHeight;
      const size = calculateSize(
        appState.layoutSettings?.resolution,
        appState.layoutSettings?.screenType,
        appState.layoutSettings?.orientation,
        clientW,
        clientH,
      );
      setSize({
        width: size.width,
        height: size.height,
        ratio: size.ratio,
        screenWidth: clientW,
        screenHeight: clientH,
      });
    }
    // eslint-disable-next-line
  }, [appState.currentDeviceObj?.group]);

  return (
    <div className="home_page h-100" id="homepage">
      {!appState.currentDeviceObj ? (
        <NoDevice size={size} />
      ) : (
        <>
          {size &&
          appState.currentDeviceObj.group &&
          appState.layoutSettings &&
          appState.currentSchedules?.length &&
          (appState.currentSchedules[0] ||
            (appState.layoutSettings.screenType === PlaylistScreenType.SplitScreen && appState.currentSchedules[1])) ? (
            (playlistFromCacheData && !playlistFromCacheData.isReady) ||
            (appState.layoutSettings.screenType === PlaylistScreenType.SplitScreen &&
              playlistFromCacheDataSecond &&
              !playlistFromCacheDataSecond.isReady) ? (
              <LoadingCacheProgress playlistFromCache={playlistFromCacheData} size={size} />
            ) : (
              <PlaylistLayout size={size} layoutSettings={appState.layoutSettings} />
            )
          ) : (
            <NoConnection size={size} />
          )}
        </>
      )}
    </div>
  );
};

export default Index;
