import React from 'react';
import imgStatusNetwork from '../../../assets/images/no_Internet.png';
import { Offline } from 'react-detect-offline';
import './style.scss';

const StatusNetWork = () => {
  return (
    <div className="status_network text-white mx-4 mt-3">
      <Offline>
        <img src={imgStatusNetwork} width={30} alt="img"></img>
      </Offline>
    </div>
  );
};

export default StatusNetWork;
