import env from '@/env';
import { useMutation } from 'react-query';
import { CachePlaylistUrls, CacheRoute } from './useOfflinePlaylist';

export default function useUpdatePlaylistsCache() {
  return useMutation(async (playlists: CachePlaylistUrls) => {
    let postRoute = `${env.piApiEndPoint}/${CacheRoute.UPDATE_CACHE}`;
    const rawResponse = await fetch(postRoute, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(playlists),
    });
    // console.log('on update playlist cache');
    const content = await rawResponse.json();
    return content;
  });
}
