import React from 'react';
import Clock from 'react-live-clock';

interface IProps {
  timezone: string;
}

function LocalClock(props: IProps) {
  return (
    <div className="clock-container">
      <div>
        <Clock format={'HH:mm'} ticking={true} interval={5000} timezone={props.timezone} />
      </div>
    </div>
  );
}

export default React.memo(LocalClock, (prevProps, nextProps) => {
  return prevProps.timezone === nextProps.timezone;
});
