import i18n from '@/i18n';
import { getDeviceCode } from '@helpers/device_code';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ImgConnect from '../../../assets/images/connect_device.svg';
import icon_device_id from '../../../assets/images/device-id.svg';
import ic_flagNO from '../../../assets/images/flag_norway.svg';
import ic_flagEN from '../../../assets/images/flag_united-kingdom.svg';
import ic_flagVN from '../../../assets/images/flag_vietnam.svg';
import IconLanguages from '../../../assets/images/ic_lang.svg';
import IconPrevious from '../../../assets/images/previous.svg';
import { globalState } from '../../../modules/state';
import './style.scss';

export enum TypeSidebar {
  LANGUAGE = 'SIDEBAR_LANGUAGE',
  DEVICE_ID = 'SIDEBAR_DEVICE_ID',
}

export enum typeLanguage {
  VI = 'vi',
  EN = 'en',
  NOR = 'no',
}

const SideBarParentArray = [
  { id: TypeSidebar.LANGUAGE, title: 'language', icon: IconLanguages },
  { id: TypeSidebar.DEVICE_ID, title: 'device_id', icon: icon_device_id },
];

const SideBarLanguageArray = [
  { id: typeLanguage.NOR, title: 'Norwegian', flag: ic_flagNO },
  { id: typeLanguage.VI, title: 'Vietnamese', flag: ic_flagVN },
  { id: typeLanguage.EN, title: 'English', flag: ic_flagEN },
];

const SideBarIndex = () => {
  const refTime = useRef<any>();
  const { t } = useTranslation(['translation']);
  const [appState, setAppState] = useRecoilState(globalState);
  const hideMenu = () => {
    setAppState((state: any) => ({
      ...state,
      visibleSideBar: false,
    }));
  };
  const deviceId = getDeviceCode();

  useEffect(() => {
    if (i18n.language) {
      setAppState((state: any) => ({
        ...state,
        currentLang: i18n.language,
      }));
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (appState.visibleSideBar) {
      setAppState((state) => {
        return { ...state, isSelectedSideBar: TypeSidebar.LANGUAGE };
      });
    }
    // eslint-disable-next-line
  }, [appState.visibleSideBar]);

  const onClickSidebarItem = (props: any) => {
    setAppState((state: any) => ({
      ...state,
      isSelectedSideBar: props.id,
    }));
  };

  const onChangeLanguage = (lang: typeLanguage) => {
    i18n.changeLanguage(lang);
    setAppState((state: any) => ({
      ...state,
      currentLang: lang,
    }));
  };

  // Xử lý sự kiện giữ chuột trái để hiển thị device code
  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;
    if (keyCode === 0x196) {
      setAppState((state: any) => ({
        ...state,
        visibleSideBar: !state.visibleSideBar,
      }));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  useEffect(() => {
    const onShowSidebar = () => {
      clearTimeout(refTime.current);
      refTime.current = setTimeout(() => {
        if (appState.visibleSideBar === false) {
          setAppState((state: any) => ({
            ...state,
            visibleSideBar: true,
          }));
        }
        clearTimeout(refTime.current);
      }, 300);
    };

    window.addEventListener('mousedown', () => {
      onShowSidebar();
    });

    window.addEventListener('touchstart', () => {
      onShowSidebar();
    });

    return () => {
      window.removeEventListener('mousedown', () => {
        clearTimeout(refTime.current);
      });
      window.removeEventListener('touchstart', () => {
        clearTimeout(refTime.current);
      });
    };
  });

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      clearTimeout(refTime.current);
    });
    window.addEventListener('touchend', () => {
      clearTimeout(refTime.current);
    });
    return () => {
      window.removeEventListener('mouseup', () => {
        clearTimeout(refTime.current);
      });
      window.removeEventListener('touchend', () => {
        clearTimeout(refTime.current);
      });
    };
  });

  return (
    <div
      id="sidebar_container"
      className={`h-100 d-flex position-fixed ${appState.visibleSideBar ? 'show_sidebar' : 'hide_sidebar'} `}
    >
      <div className="bg-sidebar w-100 h-100" onClick={hideMenu}></div>
      <div className="sidebar d-flex">
        <div className="Sidebar_parent_container bg-black">
          {SideBarParentArray.map((item) => {
            return (
              <div
                key={item.id}
                className={`Sidebar_parent_container-item ${
                  appState.isSelectedSideBar === item.id ? 'bg_container_sidebar' : ''
                }  `}
                onClick={() => {
                  onClickSidebarItem(item);
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className={`container_icon  d-flex justify-content-center align-items-center  ${
                      appState.isSelectedSideBar === item.id ? 'border_item_sidebar--active' : 'border_item_sidebar'
                    } bg-black`}
                  >
                    <img src={item.icon} width="80%" alt="icon_sidebar" />
                  </div>
                  <p className="text-white text-center pt-2 mb-1">{t(item.title)}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="Sidebar_children_container ">
          <div className="w-100">
            <div className="Sidebar_children_container--header d-flex justify-content-between align-items-center w-100">
              <p className="text-50 text-white text-bold mb-0">
                {appState.isSelectedSideBar === TypeSidebar.LANGUAGE
                  ? t('language').toUpperCase()
                  : appState.isSelectedSideBar === TypeSidebar.DEVICE_ID
                  ? t('device_id').toUpperCase()
                  : ''}
              </p>
              <div className={`border  rounded-circle border-secondary p-2`} onClick={hideMenu}>
                <img src={IconPrevious} alt="icon" />
              </div>
            </div>

            {appState.isSelectedSideBar === TypeSidebar.LANGUAGE ? (
              SideBarLanguageArray.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`sidebar_item_lang d-flex align-items-center`}
                    onClick={() => {
                      onChangeLanguage(item.id);
                    }}
                  >
                    <div className={`item_dot ${appState.currentLang === item.id ? 'bg-danger' : ''}`}></div>
                    <img src={item.flag} alt="flag_icon" />
                    <p className="text-white mb-0 text-40 ">{item.title}</p>
                  </div>
                );
              })
            ) : appState.isSelectedSideBar === TypeSidebar.DEVICE_ID ? (
              <div className="Sidebar_children-device-id w-75">
                <img className="img-rs" src={ImgConnect} alt="img" />
                <p className="text-34 text-white text-bold pt-4">{t('device_id')}</p>
                <p className="text-54 text-red text-bold letter-spacing-9">{deviceId}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideBarIndex);
