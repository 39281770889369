import { appSubscription } from '@/graphql/client';
import {} from '@/graphql/generated/graphql';
import { gql } from '@apollo/client';

export const DEVICE_SUBS = gql`
  subscription DeviceSubs {
    deviceSubs {
      data
      type
    }
  }
`;

export const useDeviceSubscription = () => {
  return appSubscription(DEVICE_SUBS);
};
