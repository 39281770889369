import { getSDK } from '../graphql/client';

export default function useSendScreenshot() {
  const SendScreenshot = async (input: string) => {
    try {
      await getSDK().SendScreenshot({ input: { file: input } });
    } catch (error) {
      console.log('error: ', error);
    }
  };
  return { SendScreenshot };
}
