import env from '@/env';
import { DeviceScreenStatus } from '@/graphql/generated/graphql';
import { globalState } from '@modules/state';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { CacheRoute } from './useOfflinePlaylist';
import useUpdateDeviceInfo from './useUpdateDeviceInfo';

export default function useGetCecStatus(token?: string | null) {
  const appState = useRecoilValue(globalState);
  const { mutate: onUpdateDeviceInfo } = useUpdateDeviceInfo();

  return useQuery(
    [],
    async () => {
      const getRoute = `${env.piApiEndPoint}/${CacheRoute.CEC_STATUS}`;
      const rawResponse = await fetch(getRoute, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const content = await rawResponse.json();
      let status;
      let localTime = null;
      if (!content?.status) {
        status = DeviceScreenStatus.Off;
      } else {
        if (content?.status === 'on' || content?.status === 'on\n') {
          status = DeviceScreenStatus.On;
        } else {
          status = DeviceScreenStatus.Off;
        }
      }
      // Gửi thông tin lên server
      const playlistId = appState.currentSchedules?.length ? appState.currentSchedules[0]?.playlist?._id : '';
      const playlistIdSecond = appState.currentSchedules?.length ? appState.currentSchedules[1]?.playlist?._id : '';
      if (appState?.currentDeviceObj?.group?.timezone) {
        localTime = moment()
          .tz(appState.currentDeviceObj.group?.timezone)
          .format(`[GMT]Z - HH:mm:ss ${appState.currentDeviceObj.momentDateFormat || 'DD.MM.YYYY'}`);
      }

      onUpdateDeviceInfo({
        input: {
          playlistIds: [playlistId || '', playlistIdSecond || ''],
          screenStatus: status,
          localTime,
        },
      });
      return content;
    },
    {
      enabled: !!token,
      refetchInterval: 20000,
      refetchIntervalInBackground: true,
    },
  );
}
