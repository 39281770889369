import { getDeviceCode } from '@helpers/device_code';
import { useTranslation } from 'react-i18next';
import ImgNotPlaylist from '../../../assets/images/img_not_playlist.png';
import logo from '../../../assets/images/logo.png';
import { SizeInterface } from './FrameComponent';

interface IProps {
  size: SizeInterface;
}

export default function NoConnection(props: IProps) {
  const { t } = useTranslation(['translation']);
  const deviceId = getDeviceCode();

  return (
    <div className="no_connect h-100 w-100">
      <div
        className={`bg-opacity-7 w-100 h-100 position-absolute d-flex justify-content-center align-items-center ${
          props.size.screenWidth > props.size.screenHeight ? 'flex-wrap flex-row' : 'flex-column'
        }`}
      >
        <div
          className={`wrapper  ${
            props.size.screenWidth > props.size.screenHeight ? 'wrapper-horizontal' : 'wrapper-vertical'
          }`}
        >
          <img
            className={`logo ${props.size.screenWidth > props.size.screenHeight ? 'logo-horizontal' : 'logo-vertical'}`}
            src={logo}
            alt="logo"
          />

          <div
            className={`container_content bg-opacity-3 ${
              props.size.screenWidth > props.size.screenHeight
                ? 'container_content-horizontal'
                : 'container_content-vertical'
            }`}
          >
            <div className=" d-flex flex-column align-items-center d-flex">
              <img
                className={`img_not_playlist  ${
                  props.size.screenWidth > props.size.screenHeight
                    ? 'img_not_playlist-horizontal'
                    : 'img_not_playlist-vertical'
                }`}
                src={ImgNotPlaylist}
                alt="img"
              />
              <span
                className={`title  ${
                  props.size.screenWidth > props.size.screenHeight ? 'title-horizontal' : 'title-vertical'
                } text-capitalize`}
              >
                {t('current_not_playlist')}
              </span>
            </div>
            <span
              className={`description_not_playlist ${
                props.size.screenWidth > props.size.screenHeight
                  ? 'description_not_playlist-horizontal'
                  : 'description_not_playlist-vertical'
              } `}
            >
              {t('please_check_playlist')}&nbsp;
              <strong className="text-red">{t('Reload')}</strong>&nbsp; {t('to_launch')}.
            </span>
            <div className="flex-column align-items-center d-flex w-100">
              <span
                className={`text-white  ${
                  props.size.screenWidth > props.size.screenHeight ? 'fs_24-horizontal' : 'fs_24-vertical'
                } `}
              >
                {t('device_id')}
              </span>
              <span
                className={`text-red text-bold fs_54 ${
                  props.size.screenWidth > props.size.screenHeight ? 'fs_54-horizontal' : 'fs_54-vertical '
                }`}
              >
                {deviceId}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
