/* eslint-disable @typescript-eslint/no-unused-vars */
import env from '../env';
import { Jwt } from '@/graphql/generated/graphql';
import { HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

const TOKEN_KEY = env.tokenKey;

let TOKEN: any = '';
let wsLink: any;
let ws: any;

export const setWsLink = (token?: string) => {
  if (!wsLink) {
    const httpLink = new HttpLink({
      uri: env.apiEndPoint,
    });

    ws = new WebSocketLink({
      uri: process.env.REACT_APP_API_END_POINT_WS || '',
      options: {
        reconnect: true,
        connectionParams: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      ws,
      httpLink,
    );
    wsLink = link;
  }
};

export const getWsLink = () => {
  return wsLink;
};

export const setAuthData = (loginStorage: Jwt) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(loginStorage || {}));
  TOKEN = loginStorage.token;
};

export const getLocalLanguage = () => {
  return sessionStorage.getItem(env.languageKey) || 'en';
};
