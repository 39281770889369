import env from '@/env';
import { useQuery } from 'react-query';
import { CacheRoute } from './useOfflinePlaylist';

export default function useGetPlaylistCache(playlistId: string, deviceVersion: number) {
  return useQuery(
    [CacheRoute.GET_PLAYLIST, playlistId, deviceVersion],
    async () => {
      const getRoute = `${env.piApiEndPoint}/${CacheRoute.GET_PLAYLIST}/${playlistId}`;
      const rawResponse = await fetch(getRoute, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const content = await rawResponse.json();
      if (!content) {
        return false;
      }
      return content;
    },
    {
      enabled: !!playlistId,
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    },
  );
}
