import { DisplayMode } from '@/graphql/generated/graphql';
import { getFrameBackgroundStyle } from '@modules/Home/helpers/check-schedule';
import { CSSProperties } from 'styled-components';

interface IProps {
  src: any;
  styleFrameContainer?: CSSProperties;
  indexFrame: Number;
  isActive?: boolean;
  duration: Number;
  displayMode?: DisplayMode | null;
}

function IFrameDocument({ src, styleFrameContainer, indexFrame, displayMode }: IProps) {
  return (
    <div
      key={`DOC_ITEM_IMG_${indexFrame}`}
      style={{
        ...styleFrameContainer,
        // backgroundColor: 'red',
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: getFrameBackgroundStyle(displayMode),
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
      }}
    />
  );
}

export default IFrameDocument;
